import request from './request';
import urlObj from './config';
const { baseUrl } = urlObj;

// 客户列表 start
const customQuery =  params => { // 客户列表
    const url = `${baseUrl}/web/customer/list`;
    return request.get(url, params)
}

const customRecharge =  params => { // 客户充值
    const url = `${baseUrl}/web/customer/recharge`;
    return request.post(url, params)
}

const customChangeActive =  params => { // 改变用户的活跃状态
    const url = `${baseUrl}/web/customer/changeActive`;
    return request.post(url, params)
}

const customElectricityBillQuery =  params => { // 客户下子列表 电费账单列表
    const url = `${baseUrl}/web/customer/bill/list`;
    return request.get(url, params)
}

const customElectricityBillRemark =  params => { // 客户下子列表 电费账单修改备注
    const url = `${baseUrl}/web/customer/bill/remark`;
    return request.post(url, params)
}

const customOtherBillQuery =  params => { // 客户下子列表 其他账单列表
    const url = `${baseUrl}/web/customer/otherBill/list`;
    return request.get(url, params)
}

const customOtherBillRemark =  params => { // 客户下子列表 其他账单修改备注
    const url = `${baseUrl}/web/customer/otherBill/remark`;
    return request.post(url, params)
}

const customOtherBillAdd = params => { // 新建其他账单
    const url = `${baseUrl}/web/customer/otherBill/create`;
    return request.post(url, params)
}

const customOtherBillDelete = params => { // 删除其他账单
    const url = `${baseUrl}/web/customer/otherBill/delete`;
    return request.post(url, params)
}

const customMinerLogQuery =  params => { // 客户下子列表 矿机日志列表
    const url = `${baseUrl}/web/customer/minerLog/list`;
    return request.get(url, params)
}

const customMinerLogRemark =  params => { // 客户下子列表 矿机日志修改备注
    const url = `${baseUrl}/web/customer/minerLog/remark`;
    return request.post(url, params)
}

const customMinerLogAdd = params => { // 新建矿机日志
    const url = `${baseUrl}/web/customer/minerLog/create`;
    return request.post(url, params)
}

const customMinerLogDelete = params => { // 删除矿机日志
    const url = `${baseUrl}/web/customer/minerLog/delete`;
    return request.post(url, params)
}

const customMinerLogModify = params => { // 修改矿机日志
    const url = `${baseUrl}/web/customer/minerLog/modify`;
    return request.post(url, params)
}

const customDailyQuery =  params => { // 客户下子列表 算力日报列表
    const url = `${baseUrl}/web/customer/daily/list`;
    return request.get(url, params)
}

const ossInfoQuery =  params => { // 获取文件上传配置信息的接口
    const url = `${baseUrl}/web/customer/oss/getPolicy`;
    return request.get(url, params)
}

const contractAdd =  params => { // 客户管理下新增合同发票
    const url = `${baseUrl}/web/customer/contract/add`;
    return request.post(url, params)
}

const contractDelete =  params => { // 客户管理下删除合同发票
    const url = `${baseUrl}/web/customer/contract/delete`;
    return request.post(url, params)
}

const contractList =  params => { // 客户管理下子列表合同发票 列表
    const url = `${baseUrl}/web/customer/contract/list`;
    return request.get(url, params)
}

const contractFileUrl =  params => { // 客户管理下子列表 单条的 图片列表请求
    const url = `${baseUrl}/web/customer/contract/fileUrl`;
    return request.post(url, params)
}
// 客户列表 end

// 电费账单 start

const customElectricityDailyQuery =  params => { // 电费账单列表
    const url = `${baseUrl}/web/customer/electricityDaily/list`;
    return request.get(url, params)
}


const customElectricityDailyModify = params => { // 修改电费更正
    const url = `${baseUrl}/web/customer/electricityDaily/modify`;
    return request.post(url, params)
}

const customElectricityDailyDeduct = params => { // 电费扣费
    const url = `${baseUrl}/web/customer/electricityDaily/deduct`;
    return request.post(url, params)
}
// 电费账单 end

// 版本管理 start
const versionAdd =  params => { // 新建版本号
    const url = `${baseUrl}/web/customer/appVersion/add`;
    return request.post(url, params)
}

const versionModify =  params => { // 编辑版本号
    const url = `${baseUrl}/web/customer/appVersion/modify`;
    return request.post(url, params)
}

const versionDelete = params => { // 删除版本号
    const url = `${baseUrl}/web/customer/appVersion/delete`;
    return request.post(url, params)
}

const versionQuery = params => { // 查询版本接口
    const url = `${baseUrl}/web/customer/appVersion/list`;
    return request.get(url, params)
}
// 版本管理 end 


const Api = {
    customQuery,
    customRecharge,
    customChangeActive,
    customElectricityBillQuery,
    customElectricityBillRemark,
    customOtherBillQuery,
    customOtherBillRemark,
    customOtherBillAdd,
    customOtherBillDelete,
    customMinerLogQuery,
    customMinerLogRemark,
    customMinerLogAdd,
    customMinerLogDelete,
    customMinerLogModify,
    customDailyQuery,
    customElectricityDailyQuery,
    customElectricityDailyModify,
    customElectricityDailyDeduct,
    contractAdd,
    contractDelete,
    contractList,
    contractFileUrl,
    ossInfoQuery,
    versionAdd,
    versionModify,
    versionDelete,
    versionQuery
}

export default { ...Api };