let baseUrl = '';
const location = window.location.href;
if (/https:\/\/fe-hosting.pool555.com/.test(location)) { // 测试和开发环境
  baseUrl = 'https://hosting.pool555.com/api';
} else if (/127.0.0.1:3000/.test(location)) {
  baseUrl = 'https://hosting.pool555.com/api';
}else { // 正式地址，暂时未知
  baseUrl = 'https://hosting.pbb.rocks/api';
}

export default {baseUrl};
