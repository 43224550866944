import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter } from 'react-router';
import { Input, Table, message, Button, Modal, Menu, Dropdown, DatePicker, Select, Form } from 'antd';
import { CheckCircleOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import RadioGroup from '../../../../components/radioGroup/index';
import ModalComponent from '../../../../components/modal';
import moment from 'moment';
import Api from '../../../../services/api';
import './index.less';

const timeArr = [{ name: '近30天', key: 0 }, { name: '全部', key: 1 }];

const actionOptions = [
    { name: '其它', key: 0 },
    { name: '矿机入场', key: 1 },
    { name: '矿机离场', key: 2 },
    { name: '矿机开机', key: 3 },
    { name: '矿机关机', key: 4 },
    { name: '矿机上架', key: 5 },
    { name: '矿机下架', key: 6 },
]

const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;


const disabledDate = (current) => {
    return current && current >= moment().endOf('day');
}

const MenuItems = ({ record, handleDeleteLog, handleModifyLog }) => {
    const arr = ['修改日志', '删除日志'];
    const handleTargetMothods = (item, record) => {
        switch(item) {
            case '修改日志':
                handleModifyLog(record)
                return false;
            case '删除日志':
                handleDeleteLog(record)
                return false;
            default: return false;
        }
    }
    const Node = (
        <Menu>
            {
                arr.map((item, index) => (
                    <Menu.Item key={index} onClick={() => handleTargetMothods(item, record)}>
                        <span>{item}</span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Dropdown overlay={Node} trigger={['click']} placement="topCenter" getPopupContainer={() => document.getElementById("minerLog")}>
                <SettingOutlined
                    style={{ fontSize: 18, color: '#4895EB' }}
                    onClick={e => { e.stopPropagation() }} />
            </Dropdown>
        </div>
    );
}

const MinerLog = (props) => {
    const { location } = props;
    const { uid } = location.state;
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const tableContainer = useRef(null);
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 16, span: 8 },
    };
    let btnDisabled = false; // 按钮锁
    const columns = [
        {
            title: '操作类型',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: text => actionOptions.filter(item => item.key === text).length > 0 ? actionOptions.filter(item => item.key === text)[0].name : ''
        },
        {
            title: '时间',
            dataIndex: 'operationTime',
            key: 'operationTime',
            align: 'center',
            render: text => moment(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '操作日志',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center',
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
            align: 'center'
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (text, record, index) => {
                return rowId === record.id ?
                    <>
                        <Input
                            style={{ width: 140 }}
                            value={text}
                            maxLength={40}
                            onChange={(event) => { handleRemarkChange(event, index) }}
                        />
                        <CheckCircleOutlined
                            style={{ marginLeft: 10, fontSize: 18, color: '#4895EB' }}
                            onClick={() => handleConfirm(record)}
                        />
                    </>
                    :
                    <span>
                        {text}
                        <EditOutlined
                            style={{ marginLeft: 8 }}
                            onClick={() => handleSwitchStatus(record.id)}
                        />
                    </span>
            },
        },
        {
            title: '操作',
            dataIndex: 'user',
            key: 'user',
            align: 'center',
            fixed: 'right',
            render: (text, record) => <span>
                <MenuItems record={record} handleDeleteLog={e => handleDeleteLog(e, record)} handleModifyLog={e => handleModifyLog(e, record)} />
            </span>
        }
    ]


    const [rowId, setRowId] = useState('') // 当前行修改备注
    const [modalShow, setModalShow] = useState(false);
    const [modifyModalShow, setModifyModalShow] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 30,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    const [rangeType, setRangeType] = useState(0)
    const [tableHeight, setTableHeight] = useState(0)

    const [logData, setLogData] = useState([{ operationTime: null, time: null, operator: '', remark: '', detail: '', action: null }]); // 新增日志列表
    const [currentPopupData, setCurrentPopupData] = useState({})

    const handleAddLog = () => {
        setModalShow(true);
        setLogData([{ operationTime: null, time: null, operator: '', remark: '', detail: '', action: null }])
    }

    const handleSwitchRangeType = event => {
        let { value } = event.target;
        const { current } = pagination;
        setRangeType(value)
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleSwitchStatus = (rowId) => {
        setRowId(rowId)
    }

    const handleRemarkChange = (event, index) => {
        let value = event.target.value;
        let data = dataSource
        data[index].remark = value;
        setDataSource([...data])
    }

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let params = `?uid=${uid}&pageNum=${pagination.current}&pageSize=${pagination.pageSize}&rangeType=${rangeType}`;
        let result = await Api.manage.customMinerLogQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            setDataSource(result.data);
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleModifyLog = record => {
        setCurrentPopupData(Object.assign({}, record))
        setModifyModalShow(true);
    }

    const handleDeleteLog = record => {
        const { current } = pagination;
        confirm({
            title: '删除日志',
            content: '确认删除日志么？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res = await Api.manage.customMinerLogDelete({ id: record.id })
                if (res.errorCode === 0) {
                    message.success('删除成功');
                    if (current !== 1) {
                        setPagination({ ...pagination, current: 1 })
                        return false;
                    }
                    getList();
                } else {
                    message.error(res.errorMsg);
                }
            }
        })
    }

    const handleConfirm = async (record) => {
        const { id, remark } = record;
        const params = {
            id,
            remark,
        };
        let res = await Api.manage.customMinerLogRemark(params);
        if (res.errorCode === 0) {
            message.success('修改成功');
            const { current } = pagination;
            if (current !== 1) {
                setPagination({ ...pagination, current: 1 })
                return false;
            }
            setRowId('')
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    const handleCancel = () => {
        setModalShow(false);
    }

    const handleFinish = async () => {
        if (btnDisabled) return false;
        btnDisabled = true;
        let params = {
            uid,
            creator: JSON.parse(localStorage.getItem('userInfo')).uid || ''
        };
        try {
            let list = [...logData];
            list.forEach((item, index, arr) => {
                const { time, operator, detail, action } = item;
                if (['', null, undefined].includes(action)) throw new Error(`第${index + 1}条的操作类型不能为空`)
                if (['', null, undefined].includes(time)) throw new Error(`第${index + 1}条的操作时间不能为空`)
                if (detail === '') throw new Error(`第${index + 1}条的操作详情不能为空`)
                if (operator === '') throw new Error(`第${index + 1}条的操作人不能为空`)
                arr[index].operationTime = moment(item.time).format('YYYY-MM-DD HH:mm:ss')
            })
            params = { ...params, list };
            let res = await Api.manage.customMinerLogAdd(params);
            btnDisabled = false;
            if (res.errorCode === 0) {
                message.success('新建成功');
                setModalShow(false);
                const { current } = pagination;
                if (current !== 1) {
                    setPagination({ ...pagination, current: 1 })
                    return false;
                }
                getList();
            } else {
                message.error(res.errorMsg);
                btnDisabled = false;
            }
        } catch (err) {
            message.error(err.message);
            btnDisabled = false;
        }

    }

    const handleOperatorTimeChange = (date, _, index) => {
        console.log(date, '------')
        if (!date) return false;
        let list = [...logData]
        list[index].time = date;
        setLogData([...list])
    }

    const handleOperatorTimeOk = (date, _, index) => {
        if (!date) return false;
        let list = [...logData]
        list[index].time = date;
        setLogData(list)
    }

    const handleDeleteRow = (index) => {
        let list = [...logData]
        list.splice(index, 1);
        setLogData(list)
    }

    const handleAddLogItem = () => { // 添加新的 日志
        let list = [...logData]
        list.push({ operationTime: null, time: null, operator: '', remark: '', detail: '', action: null })
        setLogData([...list])
    }

    const handleActionChange = (val, index) => {
        let list = [...logData]
        list[index].action = val;
        setLogData([...list])
    }

    const handleDetailChange = (event, index) => {
        const { value } = event.target;
        let list = [...logData]
        if (!/\S/.test(value)) {
            list[index].detail = '';
            setLogData([...list])
            return false;
        }
        list[index].detail = value;
        setLogData([...list])
    }

    const handleOperatorChange = (event, index) => {
        const { value } = event.target;
        let list = [...logData]
        if (!/\S/.test(value)) {
            list[index].operator = '';
            setLogData([...list])
            return false;
        }
        list[index].operator = value;
        setLogData([...list])
    }

    const handleRowsRemarkChange = (event, index) => {
        const { value } = event.target;
        let list = [...logData]
        list[index].remark = value;
        setLogData([...list])
    }

    const handleModifySuccess = async values => {
        if (btnDisabled) return false;
        btnDisabled = true;
        const params = { 
            ...values,
            id: currentPopupData.id,
            uid,
            operationTime: moment(values.operationTime).format('YYYY-MM-DD HH:mm:ss'),
            creator: JSON.parse(localStorage.getItem('userInfo')).uid || ''
        };
        let res = await Api.manage.customMinerLogModify(params);
        btnDisabled = false;
        if (res.errorCode === 0) {
            message.success('编辑成功');
            setModifyModalShow(false);
            formRef.current.resetFields();
            const { current } = pagination;
            if (current !== 1) {
                setPagination({ ...pagination, current: 1 })
                return false;
            }
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }

    useEffect(() => {
        if (modifyModalShow && formRef.current) {
            formRef.current.setFieldsValue({ ...currentPopupData, operationTime: moment(currentPopupData.operationTime)})
            console.log(formRef.current);
        }
        // eslint-disable-next-line
    }, [modifyModalShow])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [pagination.current, rangeType])

    useLayoutEffect(() => {
        setTableHeight(tableContainer?.current?.offsetTop)
    }, [])

    return (
        <div id="minerLog" className="miner_log">
            <section>
                <div className="desc_box">
                    <RadioGroup arr={timeArr} sendParent={handleSwitchRangeType} value={rangeType} />
                    <Button type="primary" onClick={handleAddLog}>添加日志</Button>
                </div>
            </section>
            <main ref={tableContainer}>
                {
                    tableHeight > 0 ?
                        <Table
                            locale={{ emptyText: '暂无数据' }}
                            loading={tableLoading}
                            dataSource={dataSource}
                            columns={columns}
                            tableLayout="fixed"
                            rowKey='id'
                            scroll={{ x: 'auto', y: document.body.clientHeight - tableHeight - 160 }}
                            pagination={pagination}
                            onChange={handleTableChange} />
                        : null
                }
            </main>
            <ModalComponent
                title={'添加日志'}
                showFooter={false}
                size={1020}
                closable={true}
                visible={modalShow}
                getPopupContainer={() => document.getElementById('minerLog')}
                modalContentClass="addLog"
                onClick={() => setModalShow(false)}

            >
                <div style={{ maxHeight: 450, overflowY: 'auto', overflowX: 'hidden' }}>
                    <table className='logCreateTable' border={1} style={{ tableLayout: 'fixed', width: '100%' }}>
                        <thead>
                            <tr>
                                <td style={{ width: 140 }}>操作类型</td>
                                <td style={{ width: 170 }}>操作时间</td>
                                <td style={{ width: 280 }}>操作详情</td>
                                <td style={{ width: 130 }}>操作人</td>
                                <td style={{ width: 160 }}>操作备注</td>
                                <td style={{ width: 80 }}>操作</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                logData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ width: 120 }}>

                                            <Select style={{ width: '100%' }} value={item.action} placeholder={'请选择操作类型'} onChange={(val) => handleActionChange(val, index)}>
                                                {
                                                    actionOptions.map(item => (
                                                        <Option key={item.key} value={item.key}>{item.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </td>
                                        <td style={{ width: 200 }}>
                                            <DatePicker
                                                format={'YYYY-MM-DD HH:mm:ss'}
                                                style={{ width: '100%' }}
                                                disabledDate={disabledDate}
                                                value={item.time}
                                                showTime
                                                onChange={(date, dateString) => handleOperatorTimeChange(date, dateString, index)}
                                                onOk={(date, dateString) => handleOperatorTimeOk(date, dateString, index)} />

                                        </td>
                                        <td style={{ width: 300 }}>
                                            <TextArea
                                                style={{ width: '100%' }}
                                                rows={4}
                                                value={item.detail}
                                                placeholder={'示例:哈密M21S/58T（1台）上架；\n哈密M21S/58T（1台）返修下架；\n哈密M21S/58T（1台）返修完成，重新上架；\n芦山S17+/76T（1台）更换配件'}
                                                maxLength={60}
                                                onChange={(event) => handleDetailChange(event, index)}
                                            />
                                        </td>
                                        <td style={{ width: 200 }}>
                                            <Input
                                                placeholder={'请输入操作人'}
                                                maxLength={20}
                                                value={item.operator}
                                                onChange={(event) => handleOperatorChange(event, index)}
                                            />
                                        </td>
                                        <td style={{ width: 200 }}>
                                            <Input
                                                value={item.remark}
                                                placeholder={'请输入备注'}
                                                maxLength={20}
                                                onChange={(event) => handleRowsRemarkChange(event, index)}
                                            />
                                        </td>
                                        <td style={{ width: 60 }}>
                                            <Button type="link" onClick={() => handleDeleteRow(index)} disabled={index === 0}>删除</Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div style={{ marginTop: 30 }}>
                    <Button type="primary" onClick={handleAddLogItem}>添加</Button>
                </div>
                <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
                    <Button type="primary" onClick={handleCancel}>取消</Button>
                    <Button type="primary" onClick={handleFinish} style={{ marginLeft: 20 }}>提交</Button>
                </div>
            </ModalComponent>
            <ModalComponent
                title={'修改日志'}
                showFooter={false}
                size={477}
                closable={true}
                visible={modifyModalShow}
                getPopupContainer={() => document.getElementById('minerLog')}
                modalContentClass="modifyLog"
                onClick={() => setModifyModalShow(false)}

            >
                <Form
                    {...layout}
                    form={form}
                    ref={formRef}
                    name="register"
                    onFinish={handleModifySuccess}
                    scrollToFirstError
                >
                    <Form.Item
                        name="action"
                        label={'操作类型'}
                        rules={[
                            { required: true, message: '请选择操作类型' },
                        ]}
                    >
                        <Select placeholder={'请选择操作类型'}>
                            {
                                actionOptions.map(item => (
                                    <Option key={item.key} value={item.key}>{item.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="operationTime"
                        label={'时间'}
                        rules={[
                            {
                                required: true,
                                message: '时间是必选的',
                            },
                        ]}
                    >
                        <DatePicker
                            format={'YYYY-MM-DD HH:mm:ss'} 
                            style={{ width: '100%' }} 
                            disabledDate={disabledDate} 
                            showTime 
                        />
                    </Form.Item>

                    <Form.Item
                        name="detail"
                        label={'操作日志'}
                        rules={[
                            { required: true, message: '请输入操作日志' },
                            { pattern: new RegExp(/\S/), message: '必须输入文字、数字或英文字母' }
                        ]}
                    >
                        <TextArea rows={6} placeholder={'示例:\n哈密M21S/58T（1台）上架；\n哈密M21S/58T（1台）返修下架；\n哈密M21S/58T（1台）返修完成，重新上架；\n芦山S17+/76T（1台）更换配件'} maxLength={60} />
                    </Form.Item>

                    <Form.Item
                        name="operator"
                        label={'操作人'}
                        rules={[
                            { required: true, message: '请输入操作人' },
                            { pattern: new RegExp(/\S/), message: '必须输入文字、数字或英文字母' }
                        ]}
                    >
                        <Input placeholder={'请输入操作人'} maxLength={20} />
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label={'备注'}
                    >
                        <Input placeholder={'请输入备注'} maxLength={20} />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" onClick={() => setModifyModalShow(false)}>
                            取消
                        </Button>
                        <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default withRouter(MinerLog);