import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import Routes from '../routes/router';

class SecondNav extends React.Component {

    static propTypes = {
        collapsed: PropTypes.bool,
    }

    static defaultProps = {
        collapsed: false,
    }

    state = {
        selectedKeys: [],
        defaultSelectedKeys: [],
        openKeys: [],
        list: []
    }

    onOpenChange = (k) => {
        // 先这么优化
        if (k.length > 0) {
            this.setState({
                ...this.state,
                openKeys: []
            }, () => {
                this.setState({
                    openKeys: [k[k.length - 1]]
                });
            })
        } else { this.setState({ openKeys: [] }); }
    };

    menuSelect = val => {
        const pathname = val;
        const rank = pathname.split('/')
        //rank = ["","policy-engine","nas-client"]
        switch (rank.length) {
            case 3:  //一级目录
                this.setState({
                    selectedKeys: [pathname]
                })
                break;
            case 4: //二级目录，要展开一个subMenu
                this.setState({
                    selectedKeys: [pathname],
                    openKeys: [rank.slice(0, 3).join('/')]
                })
                break;
            case 5: //三级目录，要展开两个subMenu
                this.setState({
                    selectedKeys: [pathname],
                    openKeys: [rank.slice(0, 4).join('/')]
                })
                break;
            default: return
        }
    }

    IsInArray = (arr, val) => {
        let testStr = ',' + arr.join(",") + ",";
        return testStr.indexOf("," + val + ",") !== -1;
    }

    // getMenuList = async () => {
    //     let role = JSON.parse(localStorage.getItem("userInfo")).roleId.toString() || ''
    //     let query = `?manageRole=${Number(role)}`
    //     let result = await Api.management.manageAuthQuery(query);
    //     if (result.errorCode === 0) {
    //         if (result.data && result.data.length === 0) return false
    //         this.setState({
    //             ...this.state,
    //             list: result.data?.[0].manageMenuList
    //         })
    //     } else {
    //         message.error(result.errorMsg);
    //     }
    // }

    componentDidMount() {
        // this.getMenuList()
        let url = this.props.location.pathname;
        this.setState({ ...this.state, selectedKeys: [url] })
        this.props.history.listen(route => {
            let url = route.pathname;
            this.setState({ ...this.state, selectedKeys: [url] })
        })
    }

    render() {
        let htm = null, key = '';
        key = this.props.location.pathname;
        
        if (key !== '/login' && key !== '/callback') {
            htm = <Menu
                subMenuCloseDelay={0.1}
                defaultSelectedKeys={[key]}
                selectedKeys={this.state.selectedKeys}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
                mode={this.props.collapsed ? 'vertical' : 'inline'}
                theme="dark"
            >
                {
                    Array.isArray(Routes) && Routes.map(item => (
                        item.hasOwnProperty('icon') ?
                            <Menu.Item key={item.key}>
                                <div>
                                    { item.icon() }
                                    <Link to={item.path}>{item.name}</Link>
                                </div>
                            </Menu.Item> : null
                    ))
                }
            </Menu>
        }

        return (
            <div>
                {htm}
            </div>
        )
    }
}


export default withRouter(SecondNav);
