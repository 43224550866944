import React from 'react';
// import AsyncComponent from './AsyncComponent'
import { UserOutlined, ThunderboltOutlined, UpCircleOutlined } from '@ant-design/icons'; // 
import UserList from '../pages/UserList/index';
import ElectricPrice from '../pages/ElectricPrice/index';
import AppVersion from '../pages/AppVersion/index';
import UserDetail from '../pages/UserList/pages/Detail';
// import ElectricOrder from '../pages/UserList/pages/ElectricOrder';
// import OtherOrder from '../pages/UserList/pages/OtherOrder';
// import Contract from '../pages/UserList/pages/Contract';
// import RateDaily from '../pages/UserList/pages/RateDaily';
// import MinerLog from '../pages/UserList/pages/MinerLog';

const Routes = [
  {
    key: '/user',
    name: '用户列表',
    icon: () => <UserOutlined />,
    path: '/user',
    component: UserList,
  },
  {
    key: '/detail',
    name: '用户详情',
    path: '/detail',
    component: UserDetail,
  },
  // {
  //   key: '/electricOrder',
  //   name: '账单流水',
  //   path: '/electricOrder',
  //   component: ElectricOrder,
  // },
  // {
  //   key: '/otherOrder',
  //   name: '其它账单',
  //   path: '/otherOrder',
  //   component: OtherOrder,
  // },
  // {
  //   key: '/contract',
  //   name: '合同、发票',
  //   path: '/contract',
  //   component: Contract,
  // },
  // {
  //   key: '/rateDaily',
  //   name: '算力日报',
  //   path: '/rateDaily',
  //   component: RateDaily,
  // },
  // {
  //   key: '/minerLog',
  //   name: '矿机日志',
  //   path: '/minerLog',
  //   component: MinerLog,
  // },
  {
    key: '/electricPrice',
    name: '电费账单',
    icon: () => <ThunderboltOutlined />,
    path: '/electricPrice',
    component: ElectricPrice
  },
  {
    // App 版本
    key: '/appVersion',
    name: 'App版本',
    icon: () => <UpCircleOutlined />,
    path: '/appVersion',
    component: AppVersion,
  }
]

export default Routes;
