import React from 'react';
import { withRouter } from 'react-router-dom';
import { message, Dropdown, Menu, Modal } from 'antd';
import ROUTES from '../../routes/router';
import Api from '../../services/api';
import { LogoutOutlined, LeftOutlined } from '@ant-design/icons';
import './header.less';

const { confirm } = Modal;
class HeaderTitleNav extends React.Component {

    handleClick = url => {
        switch (url) {
            case '/logout': // 退出登录
                confirm({
                    title: '退出登录',
                    content: '确认退出登录么?',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.handleExitLogin();
                    }
                })
                break;
            default: return '';
        }
    }

    handleExitLogin = async () => {
        let data = await Api.user.manageLogout();
        if (data.errorCode === 0) {
            message.success('退出成功');
            localStorage.removeItem('userInfo');
            window.location.href = "/"
        } else {
            message.error(data.errorMsg);
        }
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { location } = this.props
        let path = location?.pathname, name = '', isNotFirstNavigation = false;
        if (path) {
            let arr = ROUTES.filter(item => item.path === path)
            name = arr.length === 1 ? arr[0].name : '列表'
            isNotFirstNavigation = arr.length === 1 ? (!arr[0].hasOwnProperty('icon') ? true : false) : false
        }
        const menu =
            <Menu>
                <Menu.Item
                    onClick={() => this.handleClick('/logout')}
                    style={{ borderBottom: '1px solid #E8E8E8', padding: '20px 12px' }}
                >
                    <LogoutOutlined />
                    <span>退出登录</span>
                </Menu.Item>
            </Menu>
        return (
            <div id="headerNav" className="header_nav">
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    {
                        isNotFirstNavigation ? 
                        <LeftOutlined 
                            style={{ 
                                margin: '0 4px',
                                fontSize: 18
                            }}
                            onClick={this.goBack} />
                        : null
                    }
                    {name}
                </div>
                <Dropdown
                    getPopupContainer={() => document.getElementById("headerNav")}
                    overlayStyle={{
                        width: '240px', overflow: 'hidden', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                        borderRadius: 4
                    }}
                    overlayClassName="header_dropdowm"
                    trigger={['click']}
                    overlay={menu}>
                    <div className="account_box">
                        <div className={`iconUser`}></div>
                        <div>{JSON.parse(localStorage.getItem('userInfo')).nick || JSON.parse(localStorage.getItem('userInfo')).account || '未知用户'}</div>
                    </div>
                </Dropdown>
            </div>
        )
    }
}

export default withRouter(HeaderTitleNav)
