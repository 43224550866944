import { combineReducers } from 'redux'
import { VisibilityFilters } from './action'

let initUserInfo = {}
let storeUserInfo = localStorage.getItem("user_info")
try {
  initUserInfo = JSON.parse(storeUserInfo)
} catch (e) {
  console.warn(e)
}

export const user = (state = initUserInfo, action) => {
  switch (action.type) {
    case 'LOGIN':
      return Object.assign({}, action.userInfo)
    case 'LOGOUT':
      return {}
    default:
      return state
  }
}

export const todos = (state = [], action) => {
  switch (action.type) {
    case 'ADD_TODO':
      return [
        ...state,
        {
          id: action.id,
          text: action.text,
          completed: false
        }
      ]
    case 'TOGGLE_TODO':
      return state.map(todo =>
        todo.id === action.id ? { ...todo, completed: !todo.completed } : todo
      )
    default:
      return state
  }
}

export const visibilityFilter = (state = VisibilityFilters.SHOW_ALL, action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return action.filter
    default:
      return state
  }
}

export default combineReducers({
  todos,
  visibilityFilter,
  user,
})
