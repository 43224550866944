import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter } from 'react-router';
import { Select, Input, Table, message, Button, Form, Modal, Menu, Dropdown, Upload, Image, Progress } from 'antd';
import { SettingOutlined, UploadOutlined } from '@ant-design/icons';
import ModalComponent from '../../../../components/modal';
import moment from 'moment';
import Api from '../../../../services/api';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import './index.less';

const { Option } = Select;
const { confirm } = Modal;

const MenuItems = ({ record, handleOperation }) => {
    const arr = ['查看文件', '删除文件'];
    const Node = (
        <Menu>
            {
                arr.map((item, index) => (
                    <Menu.Item key={index} onClick={() => handleOperation({ ...record, type: item })}>
                        <span>{item}</span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Dropdown overlay={Node} trigger={['click']} placement="topCenter" getPopupContainer={() => document.getElementById("Contract")}>
                <SettingOutlined
                    style={{ fontSize: 18, color: '#4895EB' }}
                    onClick={e => { e.stopPropagation() }} />
            </Dropdown>
        </div>
    );
}

const Contract = (props) => {
    const { location } = props;
    const { uid } = location.state;
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const tableContainer = useRef(null);
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 16, span: 8 },
    };
    const columns = [
        {
            title: '文件类型',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: text => text === 0 ? '合同' : '发票'
        },
        {
            title: '文件名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '上传时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            render: text => moment(moment.utc(text).toDate())
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '操作',
            dataIndex: 'user',
            key: 'user',
            align: 'center',
            fixed: 'right',
            render: (text, record) => <span>
                <MenuItems record={record} handleOperation={e => handleOperation(e, record)} />
            </span>
        }
    ]
    const [modalShow, setModalShow] = useState(false);
    const [type, setType] = useState(0);
    const [dataSource, setDataSource] = useState([])
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 30,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    // eslint-disable-next-line
    const [currentPopupData, setCurrentPopupData] = useState({});
    const [fileList, setFileList] = useState([]) // 文件上传列表
    const [imagePreviewList, setImagePreviewList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [percent, setPercent] = useState(0);
    const [showProgress, setShowProgress] = useState(false)
    const [tableHeight, setTableHeight] = useState(0)
    
    const handleAddContract = () => {
        setCurrentPopupData({});
        setModalShow(true);
    }

    const handleOperation = (message) => {
        const { type, id } = message;
        switch (type) {
            case '查看文件':
                return getImgList(id);
            case '删除文件':
                return handleDeleteFile(message);
            default: return false;
        }
    }

    const getImgList = async id => {
        const res = await Api.manage.contractFileUrl({ id, uid })
        if (res.errorCode === 0) {
            let arr = res.data;
            setImagePreviewList([...arr]);
            setVisible(true);
        } else {
            message.error(res.errorMsg);
        }
    }

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let params = `?pageNum=${pagination.current}&pageSize=${pagination.pageSize}&uid=${uid}`;
        let result = await Api.manage.contractList(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            setDataSource(result.data);
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleDeleteFile = record => {
        const { current } = pagination;
        confirm({
            title: '删除文件',
            content: '确认删除文件么？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const { id } = record;
                const res = await Api.manage.contractDelete({ id });
                if (res.errorCode === 0) {
                    message.success('删除成功');
                    if (current > 1) {
                        setPagination({ ...pagination, current: 1 });
                        return false;
                    }
                    getList();
                } else {
                    message.error(res.errorMsg);
                }
            }
        })
    }

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    const handleCancel = () => {
        setModalShow(false);
        setCurrentPopupData({});
    }

    const handleFinish = values => {
        const { type, fileName } = values;
        if (type === null) {
            return message.error('文件类型不能为空');
        }
        if (!fileName) {
            return message.error('文件名称不能为空');
        }
        if (fileList.length === 0) {
            return message.error('文件不能为空，请上传');
        }
        confirm({
            title: '提示',
            content: '确认新建么？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res = await Api.manage.contractAdd({type, customerUid: uid, fileName, fileId: fileList.map(item => item.fileId)});
                if (res.errorCode === 0) {
                    message.success('新建成功');
                    formRef.current.resetFields();
                    setModalShow(false);
                    setCurrentPopupData({});
                    setFileList([]);
                    const { current } = pagination;
                    if (current > 1) {
                        setPagination({ ...pagination, current: 1 });
                        return false;
                    }
                    getList();
                } else {
                    message.error(res.errorMsg);
                }

            }
        })
    }

    const handleFileTypeChange = val => {
        setType(val);
        setFileList([]);
        if (formRef.current) {
            formRef.current.setFieldsValue({ file: { file: {}, fileList: [] } })
        }
    }

    const handleOnRemoveFile = file => {
        console.log(file);
        let arr = JSON.parse(JSON.stringify(fileList));
        arr = arr.filter(item => item.fileId !== file.fileId);
        setFileList([...arr]);
    }

    useEffect(() => {
        if (modalShow) {
            // 文件类型先给成合同
            setType(0);
            formRef.current.setFieldsValue({ type: 0 });
        }
    }, [modalShow])

    useLayoutEffect(() => {
        setTableHeight(tableContainer?.current?.offsetTop)
    }, [])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [pagination.current])

    const uploadInfo = {
        multiple: true,
        name: 'file',
        fileList: fileList,
        action: '',
        progress: {
            strokeColor: {
              '0%': '#108ee9',
              '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
        customRequest: () => {},
        beforeUpload: (file) => {
            console.log(file);
            if (/cd19update/.test(file.name) === false) {
                if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
                    return new Promise((reject) => {
                        message.error('只能上传PNG/JPG/JPEG格式的图片,请检查文件类型');
                        return reject(false);
                    })
                };
            }
            if (file.size / 1024 / 1024 > 10) {
                return new Promise((reject) => {
                    message.error('上传尺寸小于10M');
                    return reject(false);
                })
            };
            if (type === 0 && fileList.length === 50) {
                return new Promise((reject) => {
                    message.error('合同类最多只能传50个图片');
                    return reject(false);
                })
            }
            if (type === 1 && fileList.length === 10) {
                return new Promise((reject) => {
                    message.error('发票类最多只能传10个图片');
                    return reject(false);
                })
            }
            setPercent(0);
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                let wordArray = CryptoJS.lib.WordArray.create(reader.result);
                let hash = CryptoJS.SHA256(wordArray).toString();
                setShowProgress(true);
                let res = await Api.manage.ossInfoQuery(`?fileHash=${hash}`);  // 获取oss参数
                if (res.errorCode === 0) {
                    const { policy, accessKeyId, signature, bucketHost, callBackUrlPath, uploadDir } = res.data;
                    const uploadUid = res.data?.uid;
                    let callbackUrl = '';
                    if (/127.0.0.1/.test(document.domain) || /fe-hosting.pool555.com/.test(document.domain)) {
                        callbackUrl = `http://hosting-cb.blockin.net${callBackUrlPath}`;
                    } else if (/fe-hosting.pbb.rocks/.test(document.domain)) {
                        // callbackUrl = `http://hosting-cb.blockin.net${callBackUrlPath}`;
                        callbackUrl = `http://fe-hosting-cb.blockin.net${callBackUrlPath}`;
                    } else {
                        callbackUrl = `${window.location.protocol}//${document.domain}${callBackUrlPath}`;
                    }
                    let obj = {
                        "callbackUrl": callbackUrl,
                        "callbackBodyType": "application/json",
                        // eslint-disable-next-line 
                        "callbackBody": "{\"bucket\": ${bucket},\"uid\":" + "\"" + uploadUid + "\", \"customerUid\":" + "\"" + uid + "\", \"fileName\":\"" + encodeURIComponent(file.name) + "\",\"fileSize\":\"" + file.size + "\",\"fileHash\":\"" + hash + "\",\"accessKeyId\":\"" + accessKeyId + "\"}",
                    };
                    let jsonObj = JSON.stringify(obj);
                    console.log(jsonObj)
                    let param = {
                        key: `${uploadDir}${file.name}`,
                        policy,
                        OSSAccessKeyId: accessKeyId,
                        success_action_status: '200',  //必须这么写 不要问为什么
                        callback: window.btoa(jsonObj),
                        signature,
                        file, //一定在最后面
                    };
                    const formData = new FormData();  //以表单的形式传递给oss
                    Object.keys(param).forEach((key) => {
                        formData.append(key, param[key]);
                    });
                    axios({
                        url: `${bucketHost}`,
                        method: 'post',
                        withCredentials: false,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: function (progressEvent) { //原生获取上传进度的事件
                            if (progressEvent.lengthComputable) {
                                let total = progressEvent.total, loaded = progressEvent.loaded;
                                let percent = parseInt(((loaded / total).toFixed(2)) * 100);
                                setPercent(percent)
                            }
                        },
                        data: formData
                    }).then(res => {
                        if (res.errorCode === 0) {
                            message.success('上传成功');
                            let obj = res.data;
                            obj = { ...obj, name: decodeURI(obj.fileName), uid: obj.fileId }
                            fileList.push(obj);
                            setFileList([...fileList])
                            setShowProgress(false);
                        } else {
                            setShowProgress(false);
                            message.error(res.errorMsg)
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                } else if(res.errorCode === 108) {
                    let percent = 0;
                    const timer = setInterval(() => {
                        if (percent === 100) {
                            clearInterval(timer);
                            message.success('上传成功');
                            let obj = res.data;
                            obj = { ...obj, name: decodeURI(obj.fileName), uid: obj.fileId }
                            fileList.push(obj);
                            setFileList([...fileList])
                            setShowProgress(false);
                            return false;
                        }
                        setPercent(percent += 50)
                    }, 300);
                } else {
                    message.error(res.errorMsg)
                }
            }
        }
    }

    return (
        <div id="Contract" className="contract">
            {/* <header>
                <span>用户ID:</span>
                <span>{uid}</span>
                <span>用户姓名:</span>
                <span>{nick || '未知用户'}</span>
            </header> */}
            <section style={{ display: 'flex', justifyContent: 'flex-end', padding: 20 }}>
                <Button type="primary" onClick={handleAddContract} >新建合同/发票</Button>
            </section>
            <main ref={tableContainer}>
            {
                    tableHeight > 0 ? 
                    <Table
                    locale={{ emptyText: '暂无数据' }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    columns={columns}
                    tableLayout="fixed"
                    rowKey='id'
                    scroll={{ x: 'auto', y: document.body.clientHeight - tableHeight - 160 }}
                    pagination={pagination}
                    onChange={handleTableChange} />
                    : null }
            </main>
            <ModalComponent
                title={'新增合同/发票'}
                showFooter={false}
                size={477}
                closable={true}
                visible={modalShow}
                modalContentClass="addPayModal"
                onClick={() => setModalShow(false)}
            >
                <Form
                    {...layout}
                    form={form}
                    ref={formRef}
                    name="register"
                    onFinish={handleFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        name="type"
                        label={'文件类型'}
                        rules={[
                            {
                                required: true,
                                message: '文件类型是必选的',
                            },
                        ]}
                    >
                        <Select onChange={handleFileTypeChange} placeholder={'合同/发票'} >
                            <Option value={0}>合同</Option>
                            <Option value={1}>发票</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="fileName"
                        label={'文件名称'}
                        rules={[
                            {
                                required: true,
                                message: '文件名称是必填的',
                            },
                        ]}
                    >
                        <Input style={{ width: '100%' }} maxLength={30} placeholder={'请输入文件名称'} />
                    </Form.Item>

                    <Form.Item 
                        label={'文件上传'}
                    >
                        <Form.Item noStyle name="file" rules={[
                            {
                                required: true,
                                message: '请上传文件'
                            }
                        ]}>
                            <Upload 
                                {...uploadInfo} 
                                fileList={fileList}
                                onRemove={handleOnRemoveFile}>
                                <Button icon={<UploadOutlined />}>上传图片</Button>
                            </Upload>
                        </Form.Item>
                        {
                            showProgress ? 
                            <div className="percent_box">
                                <Progress percent={percent} />
                            </div> 
                            : null
                        }
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" onClick={handleCancel}>
                            取消
                        </Button>
                        <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </ModalComponent>
            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis), getContainer: () => document.getElementById('Contract') }}>
                    {
                        imagePreviewList.map((item, index) => (
                            <Image key={index} src={item} width={600} />
                        ))
                    }
                </Image.PreviewGroup>
            </div>
        </div>
    )
}

export default withRouter(Contract);