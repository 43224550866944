import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter } from 'react-router';
import { Select, Input, Table, message, Button, Form, InputNumber, Modal, Menu, Dropdown } from 'antd';
import { CheckCircleOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import TabComponent from '../../../../components/tab/index';
import RadioGroup from '../../../../components/radioGroup/index';
import ModalComponent from '../../../../components/modal';
import moment from 'moment';
import Api from '../../../../services/api';
import './index.less';

const arr = [{ name: '待确认账单', key: 0 }, { name: '已确认账单', key: 1 }];
const timeArr = [{ name: '近30天', key: 0 }, { name: '全部', key: 1 }];

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

const MenuItems = ({ record, handleDeleteNoConfirmOrder }) => {
    const arr = ['删除账单'];
    const Node = (
        <Menu>
            {
                arr.map((item, index) => (
                    <Menu.Item key={index} onClick={() => handleDeleteNoConfirmOrder(record)}>
                        <span>{item}</span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Dropdown overlay={Node} trigger={['click']} placement="topCenter" getPopupContainer={() => document.getElementById("otherOrder")}>
                <SettingOutlined
                    style={{ fontSize: 18, color: '#4895EB' }}
                    onClick={e => { e.stopPropagation() }} />
            </Dropdown>
        </div>
    );
}

const OtherOrder = (props) => {
    const { location } = props;
    const { uid } = location.state;
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const tableContainer = useRef(null);
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 16, span: 8 },
    };
    let btnDisabled = false; // 按钮锁
    const noConfirmColumns = [
        {
            title: '扣费账户',
            dataIndex: 'currency',
            key: 'currency',
            align: 'center',
            render: text => text === 0 ? 'CNY' : 'USD'
        },
        {
            title: '账单金额',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
        },
        // {
        //     title: '扣费费率',
        //     dataIndex: 'currency',
        //     key: 'currency',
        //     align: 'center'
        // },
        {
            title: '账单说明',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center'
        },
        {
            title: '账单添加时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            render: text => moment(moment.utc(text).toDate())
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (text, record, index) => {
                return rowId === record.id ?
                    <>
                        <Input
                            style={{ width: 140 }}
                            value={text}
                            min={0}
                            onChange={(event) => { handleRemarkChange(event, index) }}
                        />
                        <CheckCircleOutlined
                            style={{ marginLeft: 10, fontSize: 18, color: '#4895EB' }}
                            onClick={() => handleConfirm(record)}
                        />
                    </>
                    :
                    <span>
                        {text}
                        <EditOutlined
                            style={{ marginLeft: 8 }}
                            onClick={() => handleSwitchStatus(record.id)}
                        />
                    </span>
            },
        },
        {
            title: '操作',
            dataIndex: 'user',
            key: 'user',
            align: 'center',
            fixed: 'right',
            render: (text, record) => <span>
                <MenuItems record={record} handleDeleteNoConfirmOrder={e => handleDeleteNoConfirmOrder(e, record)} />
            </span>
        }
    ]

    const confirmColumns = [
        {
            title: '扣费账户',
            dataIndex: 'currency',
            key: 'currency',
            align: 'center',
            render: text => text === 0 ? 'CNY' : 'USD'
        },
        {
            title: '账单金额',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
        },
        // {
        //     title: '扣费费率',
        //     dataIndex: 'currency',
        //     key: 'currency',
        //     align: 'center',
        // },
        {
            title: '账单说明',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center'
        },
        {
            title: '账单添加时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            render: text => moment(moment.utc(text).toDate())
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '账单确认时间',
            dataIndex: 'confirmTime',
            key: 'confirmTime',
            align: 'center',
            render: text => moment(moment.utc(text).toDate())
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (text, record, index) => {
                return rowId === record.id ?
                    <>
                        <Input
                            style={{ width: 140 }}
                            value={text}
                            maxLength={40}
                            onChange={(event) => { handleRemarkChange(event, index) }}
                        />
                        <CheckCircleOutlined
                            style={{ marginLeft: 10, fontSize: 18, color: '#4895EB' }}
                            onClick={() => handleConfirm(record)}
                        />
                    </>
                    :
                    <span>
                        {text}
                        <EditOutlined
                            style={{ marginLeft: 8 }}
                            onClick={() => handleSwitchStatus(record.id)}
                        />
                    </span>
            },
        },
    ]
    const [rowId, setRowId] = useState('') // 当前行修改备注
    const [modalShow, setModalShow] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 30,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    const [billType, setBillType] = useState(0)
    const [rangeType, setRangeType] = useState(0)
    const [currency, setCurrency] = useState(-1)
    // eslint-disable-next-line
    const [dataObj, setDataObj] = useState({
        cnyTotal: 0,
        usdTotal: 0
    })
    const [tableHeight, setTableHeight] = useState(0)

    const handleAddOrder = () => {
        setModalShow(true);
        if (formRef.current) {
            formRef.current.resetFields()
        }
    }

    const handleSwitchBillType = type => {
        setBillType(Number(type))
        const { current } = pagination;
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleSwitchRangeType = event => {
        let { value } = event.target;
        const { current } = pagination;
        setRangeType(value)
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleCurrencyChange = currency => {
        setCurrency(currency);
        const { current } = pagination;
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleSwitchStatus = (rowId) => {
        setRowId(rowId)
    }

    const handleRemarkChange = (event, index) => {
        let value = event.target.value;
        let data = dataSource
        data[index].remark = value;
        setDataSource([...data])
    }

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let params = `?uid=${uid}&billType=${billType}&rangeType=${rangeType}&currency=${currency !== -1 ? currency : ''}&pageNum=${pagination.current}&pageSize=${pagination.pageSize}`;
        let result = await Api.manage.customOtherBillQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            if (Object.keys(result.data).length > 0) {
                setDataSource(result.data.list);
                const { cnyTotal, usdTotal, cnyOpened, usdOpened } = result.data;
                setDataObj({ cnyTotal, usdTotal, cnyOpened, usdOpened })
            }
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleDeleteNoConfirmOrder = record => {
        const { current } = pagination;
        confirm({
            title: '删除账单',
            content: '确认删除账单么？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res = await Api.manage.customOtherBillDelete({ id: record.id })
                if (res.errorCode === 0) {
                    message.success('删除成功');
                    if (current !== 1) {
                        setPagination({ ...pagination, current: 1 })
                        return false;
                    }
                    getList();
                } else {
                    message.error(res.errorMsg);
                }
            }
        })
    }

    const handleConfirm = async (record) => {
        const { id, remark } = record;
        const params = { 
            id,
            remark,
        };
        let res = await Api.manage.customOtherBillRemark(params);
        if (res.errorCode === 0) {
            message.success('修改成功');
            const { current } = pagination;
            if (current !== 1) {
                setPagination({ ...pagination, current: 1 })
                return false;
            }
            setRowId('')
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    const handleCancel = () => {
        setModalShow(false);
    }

    const handleFinish = async values => {
        if (btnDisabled) return false;
        btnDisabled = true;
        const params = { 
            ...values,
            uid,
            operator: JSON.parse(localStorage.getItem('userInfo')).uid || ''
        };
        let res = await Api.manage.customOtherBillAdd(params);
        btnDisabled = false;
        if (res.errorCode === 0) {
            message.success('新增成功');
            const { current } = pagination;
            if (current !== 1) {
                setPagination({ ...pagination, current: 1 })
                return false;
            }
            setModalShow(false);
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }
    useLayoutEffect(() => {
        setTableHeight(tableContainer?.current?.offsetTop)
    }, [])

    useEffect(() => {
        getList()
        // eslint-disable-next-line 
    }, [pagination.current, rangeType, currency, billType])
    
    return (
        <div id="otherOrder" className="other_order">
            <section>
                <TabComponent arr={arr} sendParent={handleSwitchBillType} activeKey={billType} />
                <div className="description">
                    {/* {
                        billType !== 0 ? '客户端点击确认账单时，待确认账单转移至已确认账单并扣费' : ''
                    } */}
                </div>
                <div className="desc_box">
                    <div>
                        <span>{billType === 0 ? '待确认总额' : '已确认总额'}:</span>
                        <span>
                            {
                                currency !== 1 ? <>
                                    <b>￥</b>
                                    <span>{Number(dataObj.cnyTotal).toFixed(2)}</span>
                                </> : null
                            }
                            {
                                currency !== 0 ? <>
                                    <b>$</b>
                                    <span>{Number(dataObj.usdTotal).toFixed(2)}</span>    
                                </> : null
                            }
                            {/* <span className="desc">
                                {billType === 0 ? '待确认总额是该用户所有未确认账单的金额总计' : '已确认总额是该用户所有已确认账单的金额总计'}
                            </span> */}
                        </span>
                    </div>
                    <div>
                        <RadioGroup arr={timeArr} sendParent={handleSwitchRangeType} value={rangeType} />
                        <Select
                            style={{ marginLeft: 20, width: 160 }}
                            value={currency}
                            onChange={handleCurrencyChange}>
                            <Option value={-1}>全部</Option>
                            <Option value={0}>CNY</Option>
                            <Option value={2}>USD</Option>
                        </Select>

                        {billType === 0 ? <Button type="primary" style={{ marginLeft: 20 }} onClick={handleAddOrder}>添加账单</Button> : null}
                    </div>
                </div>
            </section>
            <main ref={tableContainer}>
                {
                    tableHeight > 0 ?
                    <Table
                    locale={{ emptyText: '暂无数据' }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    columns={billType === 0 ? noConfirmColumns : confirmColumns}
                    tableLayout="fixed"
                    rowKey='id'
                    scroll={{ x: 'auto', y: document.body.clientHeight - tableHeight - 160 }}
                    pagination={pagination}
                    onChange={handleTableChange} />
                    : null
                }
            </main>
            <ModalComponent
                title={'新增账单'}
                showFooter={false}
                size={477}
                closable={true}
                visible={modalShow}
                modalContentClass="addPayModal"
                onClick={() => setModalShow(false)}
            >
                <Form
                    {...layout}
                    form={form}
                    ref={formRef}
                    name="register"
                    onFinish={handleFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        name="currency"
                        label={'扣费账户'}
                        rules={[
                            {
                                required: true,
                                message: '扣费账户是必选的',
                            },
                        ]}
                    >
                        <Select placeholder={'USD/CNY'} >
                            <Option disabled={dataObj.usdOpened === 0} value={2}>USD</Option>
                            <Option disabled={dataObj.cnyOpened === 0} value={0}>CNY</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="num"
                        label={'账单金额'}
                        rules={[
                            {
                                required: true,
                                message: '账单金额是必填的',
                            },
                            {
                                pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                message: '最多输入两位小数',
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder={'请输入充值金额'} min={0} />
                    </Form.Item>

                    <Form.Item
                        name="detail"
                        label={'账单说明'}
                        rules={[
                            { required: true, message: '请输入账单金额' },
                            { pattern: new RegExp(/\S/), message: '必须输入文字、数字或英文字母' }
                        ]}
                    >
                        <TextArea rows={4} placeholder={'示例:\n协鑫M21S/58T（50台）上架费;\n恒联S17+/76T（1台）返修运费;'} maxLength={60} />
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label={'备注'}
                    >
                        <Input placeholder={'请输入备注'} maxLength={20} />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" onClick={handleCancel}>
                            取消
                        </Button>
                        <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default withRouter(OtherOrder);