import React, { useState, useEffect } from 'react';

import { Table, message, Button, Select, Input, Modal, Row, Col } from 'antd';

import ModalComponent from '../../components/modal/index';
import Api from '../../services/api';

import './index.less';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

// App 版本页面
export default function AppVersion(props) {
    const columns = [
        {
            title: '平台',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            filters: [
                { text: 'iOS', value: 'ios' },
                { text: 'Android', value: 'android' }
            ],
            onFilter: (value, record) => record.type === value,
            // width: '308px',
        },
        {
            title: '权重',
            dataIndex: 'build',
            key: 'build',
            align: 'center',
        },
        {
            title: '版本号',
            dataIndex: 'version',
            key: 'version',
            align: 'right',
        },
        {
            title: '下载地址',
            dataIndex: 'downloadLink',
            key: 'downloadLink',
            align: 'center',
        },
        {
            title: '更新描述',
            dataIndex: 'desc',
            key: 'desc',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'user',
            key: 'user',
            align: 'center',
            fixed: 'right',
            render: (text, record) => <span>
                <Button type="link" style={{ marginLeft: '10px' }} onClick={handleModifyItems.bind(this, record)}>编辑</Button>
                <Button type="link" style={{ marginLeft: '10px' }} onClick={handleDeleteItems.bind(this, record)}>删除</Button>
            </span>
        }
    ]


    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        pageSize: 30,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    const [tableLoading, setTableLoading] = useState(false);
    const [currentPopupData, setCurrentPopupData] = useState({
        type: 'ios',
    }); // 当前操控对象
    const [modifyModalShow, changeModifyModalShow] = useState(false); // 新增弹窗bool
    const [btnDisabled, setBtnDisabled] = useState(false) // 新增确定按钮锁
    const platformList = [
        { label: 'iOS', value: 'ios' },
        { label: 'Android', value: 'android' }
    ]

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let params = `?pageNum=${pagination.current}&pageSize=${pagination.pageSize}`;
        let result = await Api.manage.versionQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            setDataSource(result.data);
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };


    const handleModifyClose = (value) => {
        changeModifyModalShow(() => value);
    }

    const handleAdd = () => { // 新增
        changeModifyModalShow(() => true);
    }

    const handleCancel = () => { // 取消新增
        changeModifyModalShow(() => false);
    }

    const handleModifyItems = record => { // 版本号编辑
        setCurrentPopupData(record);
        changeModifyModalShow(() => true);
    }

    const handleDeleteItems = record => { // 当前删除的对象
        confirm({
            title: `确认删除${record.version}此版本号么?`,
            content: '谨慎操作，App可能会受影响',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let params = { id: record.id };
                let result = await Api.manage.versionDelete(params);
                if (result.errorCode === 0) {
                    message.success('删除成功');
                    setCurrentPopupData({ type: 'ios' });
                    setPagination({ ...pagination, current: 1 });
                    getList();
                } else {
                    message.error(result.errorMsg);
                }
            },
            onCancel() {
            },
        });
    }

    const handlePlatformChange = value => { // 平台变化回调,
        setCurrentPopupData({
            ...currentPopupData,
            type: value,
        })
    }

    const handleLevelChange = event => { // 版本权重变化
        event.persist();
        let value = event.target.value;
        setCurrentPopupData({
            ...currentPopupData,
            build: value
        })
    }
    const handleVersionChange = event => { // 版本号码变化
        event.persist();
        let value = event.target.value;
        setCurrentPopupData({
            ...currentPopupData,
            version: value
        })
    }
    const handleDownloadLinkChange = event => { // 下载地址变化
        event.persist();
        let value = event.target.value;
        setCurrentPopupData({
            ...currentPopupData,
            downloadLink: value
        })
    }

    const handleDescChange = event => { // 版本描述变化
        event.persist();
        let value = event.target.value;
        setCurrentPopupData({
            ...currentPopupData,
            desc: value
        })
    }

    const handleConfirm = async () => { // 新增 or 编辑确定
        if (!currentPopupData.build || !currentPopupData.version || !currentPopupData.downloadLink) return message.error('权重、版本号、下载地址是必填的');
        if (!btnDisabled) {
            setBtnDisabled(true);
            let params = { ...currentPopupData }
            let result = await Api.manage[currentPopupData.hasOwnProperty("id") ? 'versionModify' : 'versionAdd'](params);
            setBtnDisabled(false);
            if (result.errorCode === 0) {
                changeModifyModalShow(false);
                message.success(currentPopupData.hasOwnProperty("id") ? '编辑成功' : '新增成功');
                setCurrentPopupData({ type: 'ios' });
                getList();
            } else {
                message.error(result.errorMsg);
            }
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [pagination.current])

    return (
        <div className="app_version">
            <div className="app_version_container">
                <header>
                    <Button
                        type="primary"
                        style={{ marginLeft: '10px' }}
                        onClick={handleAdd}>新增</Button>
                </header>
                <Table
                    locale={{ emptyText: '暂无数据' }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    columns={columns}
                    tableLayout="fixed"
                    rowKey='id'
                    scroll={{ x: 1400 }}
                    pagination={pagination}
                    onChange={handleTableChange} />
                <ModalComponent
                    title={`${currentPopupData.hasOwnProperty("id") ? '编辑' : '新增'}版本`}
                    showFooter={false}
                    size={577}
                    closable={true}
                    maskClosable={false}
                    visible={modifyModalShow}
                    destroyOnClose={true}
                    modalContentClass="modalprivateServerContent"
                    onClick={handleModifyClose}
                >
                    <div className="server_modify_content">
                        <Row gutter={4}>
                            <Col span={4} style={{ alignSelf: 'center' }}>
                                <span>选择平台</span>
                            </Col>
                            <Col span={18}>
                                <Select
                                    disabled={currentPopupData.hasOwnProperty("id")}
                                    style={{ width: '220px' }}
                                    value={currentPopupData.type}
                                    onChange={handlePlatformChange}
                                >
                                    {
                                        platformList.map(item => (
                                            <Option key={item.value} value={item.value}>{item.label}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={4} style={{ marginTop: 20 }} >
                            <Col span={4} style={{ alignSelf: 'center' }}>
                                <span>版本权重</span>
                            </Col>
                            <Col span={18}>
                                <Input
                                    type="number"
                                    style={{ width: '220px' }}
                                    placeholder="请输入版本权重"
                                    value={currentPopupData.build}
                                    onChange={handleLevelChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={4} style={{ marginTop: 20 }} >
                            <Col span={4} style={{ alignSelf: 'center' }}>
                                <span>版本号码</span>
                            </Col>
                            <Col span={18}>
                                <Input
                                    disabled={currentPopupData.hasOwnProperty("id")}
                                    style={{ width: '220px' }}
                                    placeholder="请输入版本号码"
                                    value={currentPopupData.version}
                                    onChange={handleVersionChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={4} style={{ marginTop: 20 }} >
                            <Col span={4} style={{ alignSelf: 'center' }}>
                                <span>下载地址</span>
                            </Col>
                            <Col span={18}>
                                <Input
                                    style={{ width: '220px' }}
                                    placeholder="请输入下载地址"
                                    value={currentPopupData.downloadLink}
                                    onChange={handleDownloadLinkChange}
                                />
                            </Col>
                        </Row>
                        <Row gutter={4} style={{ marginTop: 20 }} >
                            <Col span={4} style={{ alignSelf: 'center' }}>
                                <span>版本描述</span>
                            </Col>
                            <Col span={18}>
                                <TextArea
                                    rows={6}
                                    style={{ width: '220px' }}
                                    allowClear
                                    placeholder={"版本描述，通过英文逗号 ’ , ‘ 分割为另一条描述"}
                                    value={currentPopupData.desc}
                                    onChange={handleDescChange}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Button
                            style={{ width: '90px', background: '#fff', color: '#4895EB' }}
                            type="primary"
                            size="large"
                            onClick={handleCancel}
                        >取消</Button>
                        <Button
                            style={{ marginLeft: 10, width: '90px' }}
                            type="primary"
                            size="large"
                            onClick={handleConfirm}
                        >确定</Button>
                    </div>
                </ModalComponent>
            </div>
        </div>
    )
}

