import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import './index.less';
import ElectricOrder from '../ElectricOrder/index';
import OtherOrder from '../OtherOrder/index'
import Contract from '../Contract/index';
import RateDaily from '../RateDaily/index';
import MinerLog from '../MinerLog/index';

const { TabPane } = Tabs;

const sourceArray = [
    { key: 'electricOrder', name: '账单流水', component: <ElectricOrder /> },
    { key: 'otherOrder', name: '其它账单', component: <OtherOrder /> },
    { key: 'minerLog', name: '矿机日志', component: <MinerLog /> },
    { key: 'rateDaily', name: '算力日报', component: <RateDaily /> },
    { key: 'contract', name: '合同/发票', component: <Contract /> },
]

const UserDetail = (props) => {
    const { location } = props;
    const { nick, id, firstCurrency } = location?.state;
    const TabBarComponent = (props) => {
        const { panes, activeKey } = props;
        return (
            <div className="tab_bar">
                {
                    panes.map(item => (
                        <div 
                            key={item.key} 
                            className="tab_bar_item"
                            onClick={() => { handleCurrencyKeyChange(item.key) }}
                        >
                            <img 
                                src={
                                    require(`../../../../assets/img/chp_${item.key}_icon.png`)}
                                alt="" />
                            <span style={{ color: activeKey === item.key ? '#4895EB' : '' }}>
                                {item.props.tab}
                            </span>
                        </div>
                    ))
                }
            </div>
        )
    }

    const [currentKey, setCurrentKey] = useState('electricOrder'); // 当前激活标签

    const handleCurrencyKeyChange = (val) => {
        setCurrentKey(val);
        sessionStorage.setItem('currentKey', val);
    }

    useEffect(() => {
        const key = sessionStorage.getItem('currentKey');
        if (key) setCurrentKey(key)
    }, []);

    return (
        <div className="user_detail">
            <header>
                <div>
                    <span>用户ID：</span>
                    <span className="value">{id}</span>
                </div>
                <div>
                    <span className="title">用户姓名：</span>
                    <span className="value">{nick}</span>
                </div>
                <div>
                    <span className="title">优先扣费账户：</span>
                    <span className="value">{firstCurrency === 0 ? 'CNY' : 'USD'}</span>
                </div>
            </header>
            <Tabs activeKey={currentKey} renderTabBar={TabBarComponent}>
                {
                    sourceArray.map(item => (
                        <TabPane className="tab_pane" tab={item.name} key={item.key}>
                            {item.component}
                        </TabPane>
                    ))
                }
            </Tabs>
        </div>
    )
}

export default UserDetail;