import React from 'react';
import { Radio } from 'antd';

const RadioComponent = ({ arr, sendParent, value }) => {
    return (
        <Radio.Group value={value} onChange={sendParent} buttonStyle="solid">
            { arr.map(item => 
                <Radio.Button key={item.key} value={item.key}>{item.name}</Radio.Button>)
            }
    </Radio.Group>
    )
}

export default RadioComponent;