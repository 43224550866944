import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from "react-redux";
import { login } from "../../store/action";

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Input, Button, Form, message } from 'antd'; // message

import OnePassed from '../../components/OnePass';

import Api from '../../services/api';

import './login.less';

class NormalLoginForm extends React.Component {
    formRef = React.createRef()
    state = {
        display: false,
        account: '',
        password: '',
    }

    handleSubmit = values => {
        this.setState({
            ...values,
            display: true,
        })
    };

    handleAccountChange = event => {
        event.persist();
        let value = event.target.value;
        if (value === '') {
            this.setState({
                ...this.state,
                display: false,
            })
        }
    }

    handlePwdChange = event => {
        event.persist();
        let value = event.target.value;
        if (value === '') {
            this.setState({
                ...this.state,
                display: false,
            })
        }
    }

    handleSubmitOnePass = async val => {
        const { dispatch } = this.props;
        let obj = { ...val, ...this.state };
        console.log(obj)
        let result = await Api.user.manageLogin(obj);
        if (result.errorCode === 0) {
            message.success('登录成功');
            let userInfo = result.data;
            dispatch(login(userInfo))
        } else {
            if (result.errorCode === 114) {
                message.error('账号审核中，请联系管理员');
                return false;
            }
            message.error(result.errorMsg);
        }
    }

    render() {
        return (
            <Form ref={this.formRef} onFinish={this.handleSubmit} className="login-form">
                <Form.Item name="account"
                    rules={[
                        { required: true, message: '请您输入用户名!' }
                    ]}
                >
                    <Input
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="用户名称"
                        size="large"
                        onChange={this.handleAccountChange}
                    />
                </Form.Item>
                <Form.Item name="password"
                    hasFeedback
                    rules={[
                        { required: true, message: '请您输入密码!' }
                    ]}>
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="用户密码"
                        size="large"
                        onChange={this.handlePwdChange}
                    />
                </Form.Item>
                <Form.Item style={{ height: '40px' }}>
                    <OnePassed display={this.state.display} width={'100%'} OnePassed={this.handleSubmitOnePass.bind(this)} />
                </Form.Item>
                <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }} className="login-form-button">
                    登录
                </Button>
            </Form>
        );
    }
}

const WrappedNormalLoginForm = connect()(NormalLoginForm);
const LoginForm = withRouter(WrappedNormalLoginForm)

class RegisterFormFunc extends React.Component {
    formRef = React.createRef()
    state = {
        display: false,
        account: '',
        password: '',
    }

    handleSubmit = values => {
        this.setState({
            ...values,
            display: true,
        })
    };

    handleAccountChange = event => {
        event.persist();
        let value = event.target.value;
        if (value === '') {
            this.setState({
                ...this.state,
                display: false,
            })
        }
    }

    handlePwdChange = event => {
        event.persist();
        let value = event.target.value;
        if (value === '') {
            this.setState({
                ...this.state,
                display: false,
            })
        }
    }

    handleSubmitOnePass = async val => {
        // 提交阿里云验证
        let obj = { ...val, ...this.state };
        console.log(obj);
        let result = await Api.user.manageRegister(obj);
        if (result.errorCode === 0) {
            message.success('注册成功，请联系管理员审核账号');
            this.props.sendParent(true)
        } else {
            message.error('注册失败');
        }
    }

    render() {
        return (
            <Form
                ref={this.formRef} onFinish={this.handleSubmit} className="login-form">
                <Form.Item
                    name="account"
                    rules={[
                        { required: true, message: '请您输入用户名!' },
                        {
                            pattern: /^[@.-_a-zA-Z0-9]+$/,
                            message: '不能包含中文以及特殊符号',
                        },
                    ]}>
                    <Input
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="用户名称"
                        size="large"
                        maxLength={32}
                        onChange={this.handleAccountChange}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    hasFeedback
                    rules={[
                        { required: true, message: '请您输入密码!' },
                        {
                            min: 6,
                            max: 20,
                            message: '请输入不少于6位的密码',
                        }
                    ]}>
                    <Input.Password
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="用户密码"
                        size="large"
                        maxLength="20"
                        onChange={this.handlePwdChange}
                    />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请您再次输入密码!',
                        },
                        {
                            min: 6,
                            max: 20,
                            message: '请输入不少于6位的密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject('两次密码不一致，请重新输入');
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        size="large"
                        placeholder={'重复密码'}
                        maxLength="20" />
                </Form.Item>
                <Form.Item style={{ height: '40px' }}>
                    <OnePassed display={this.state.display} width={'100%'} OnePassed={this.handleSubmitOnePass.bind(this)} />
                </Form.Item>
                <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }} className="login-form-button">
                    注册
                </Button>
            </Form>
        );
    }
}

const RegisterForm = connect()(RegisterFormFunc);


class LoginComponent extends React.Component {

    state = {
        isRegister: false
    }

    handleSwitchStatus = () => {
        this.setState({ isRegister: !this.state.isRegister })
    }

    getChildVal = val => {
        if (val) {
            this.setState({
                ...this.state,
                isRegister: false
            })
        }
    }

    render() {
        const { isRegister } = this.state;
        return (
            <div className="login_page">
                <div className="login_content">
                    <div className="login_content_title">
                        <span style={{ fontSize: 20, color: '#333' }}>矿机管家</span>
                        <span>(管理后台)</span>
                    </div>
                    <div className="login_content_model">
                        {
                            isRegister ? <RegisterForm sendParent={this.getChildVal} /> : <LoginForm />
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="link" onClick={this.handleSwitchStatus}>{isRegister ? '登录' : '注册'}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginComponent