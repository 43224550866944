import React from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const Tab = ({ arr, sendParent, activeKey }) => {
    return (
        <Tabs defaultActiveKey={'0'}  type="card" activeKey={String(activeKey)} onChange={sendParent} >
            { arr.map(item => <TabPane tab={item.name} key={item.key} />) }
        </Tabs>
    )
}

export default Tab