import React, { useState, useEffect, useRef ,useLayoutEffect } from 'react';
import { withRouter } from 'react-router';
import { Table, message, DatePicker } from 'antd';
import moment from 'moment';
import Api from '../../../../services/api';
import { numberUnitFormat } from '../../../../utils/helper';
import './index.less';

const disabledDate = current => {
    return current && current + 86400000 >= moment().endOf('day');
}

const RateDaily = (props) => {
    const { location } = props;
    const { uid } = location.state;
    const columns = [
        {
            title: '机型',
            dataIndex: 'model',
            key: 'model',
            align: 'center',
            render: (text, record) => text + record.subModel
        },
        {
            title: '数量',
            dataIndex: 'total',
            key: 'total',
            align: 'center',
        },
        {
            title: '理论算力',
            dataIndex: 'standRate',
            key: 'standRate',
            align: 'center',
            render: text => `${numberUnitFormat(text).size + numberUnitFormat(text).unit}`
        },
        {
            title: '实际算力',
            dataIndex: 'avgRate',
            key: 'avgRate',
            align: 'center',
            render: text => `${numberUnitFormat(text).size + numberUnitFormat(text).unit}`
        },
        {
            title: '达标率',
            dataIndex: 'reachRate',
            key: 'reachRate',
            align: 'center',
            render: text => Number(text).toFixed(2) + '%'
        }
    ]
    const [dataSource, setDataSource] = useState([])
    const [dateObj, setDateObj] = useState({
        date: null,
        dateString: '',
    });
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 30,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });

    const tableContainer = useRef(null);
    const [tableHeight, setTableHeight] = useState(0)

    const handleDateChange = (date, dateString) => {
        setDateObj({ date, dateString })
        if (pagination.current > 1) {
            setPagination({ ...pagination, current : 1 })
        }
    }

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let params = `?uid=${uid}&date=${dateObj.dateString.replace(/\//g, '-')}&pageNum=${pagination.current}&pageSize=${pagination.pageSize}`;
        let result = await Api.manage.customDailyQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            setDataSource(result.data);
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    useEffect(() => {
        let date = new Date().getTime() - 86400000;
        let year = new Date(date).getFullYear();
        let month = new Date(date).getMonth() + 1 > 9 ? new Date(date).getMonth() + 1 : `0${new Date(date).getMonth() + 1}`;
        let hours = new Date(date).getHours();
        let day = '';
        if (hours >= 8) {
            day = new Date(date).getDate() > 9 ? new Date(date).getDate() : `0${new Date(date).getDate()}`;;
        } else {
            year = new Date(date - 86400000).getFullYear();
            month = new Date(date - 86400000).getMonth() + 1 > 9 ? new Date(date - 86400000).getMonth() + 1 : `0${new Date(date - 86400000).getMonth() + 1}`;
            day = new Date(date - 86400000).getDate() > 9 ? new Date(date - 86400000).getDate() : `0${new Date(date - 86400000).getDate()}`;
        }
        setDateObj({ date: moment(`${year}/${month}/${day}`), dateString: `${year}/${month}/${day}` })
    }, [])

    useEffect(() => {
        if (dateObj.dateString !== "") {
            // const { current } = pagination;
            getList()
        }
         // eslint-disable-next-line
    }, [dateObj.dateString, pagination.current])

    useLayoutEffect(() => {
        setTableHeight(tableContainer?.current?.offsetTop)
    }, [])

    return (
        <div id="RateDaily" className="rate_daily">
            {/* <header>
                <span>用户ID:</span>
                <span>{uid}</span>
                <span>用户姓名:</span>
                <span>{nick || '未知用户'}</span>
            </header> */}
            <section>
                <DatePicker
                    allowClear={false}
                    style={{ width: 200 }}
                    value={dateObj.date}
                    onChange={handleDateChange}
                    disabledDate={disabledDate} />
            </section>
            <main ref={tableContainer}>
            {
                    tableHeight > 0 ? 
                    <Table
                    locale={{ emptyText: '暂无数据' }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    columns={columns}
                    tableLayout="fixed"
                    rowKey='id'
                    scroll={{ x: 'auto', y: document.body.clientHeight - tableHeight - 160 }}
                    pagination={pagination}
                    onChange={handleTableChange} />
                    : null }
            </main>
        </div>
    )
}

export default withRouter(RateDaily);