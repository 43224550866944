import request from './request';
import urlObj from './config';
const { baseUrl } = urlObj;

const manageLogin =  params => { // 登录接口
    const url = `${baseUrl}/web/user/login`;
    return request.post(url, params)
}

const manageLogout =  params => { // 退出登录
    const url = `${baseUrl}/web/user/logout`;
    return request.get(url, params)
}

const manageRegister =  params => { // 注册接口
    const url = `${baseUrl}/web/user/register`;
    return request.post(url, params)
}

const Api = {
    manageLogin,
    manageLogout,
    manageRegister
}

export default { ...Api };