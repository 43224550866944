let nextTodoId = 0

export const login = userInfo => {
  localStorage.setItem("userInfo", JSON.stringify(userInfo))

  return {
    type: 'LOGIN',
    userInfo
  }
}

export const logout = () => {
  localStorage.removeItem("userInfo")

  return {
    type: 'LOGOUT',
  }
}

export const addTodo = text => ({
  type: 'ADD_TODO',
  id: nextTodoId++,
  text
})

export const setVisibilityFilter = filter => ({
  type: 'SET_VISIBILITY_FILTER',
  filter
})

export const toggleTodo = id => ({
  type: 'TOGGLE_TODO',
  id
})

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE'
}
