import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { Table, message, Input, Button, Form, Select, InputNumber, Modal, Menu, Dropdown } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import ModalComponent from '../../components/modal/index';
import Api from '../../services/api';
import './index.less';

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

const ActiveStatusArray = [
    { id: 99, name: '全部用户' },
    { id: 0, name: '活跃用户' },
    { id: 1, name: '非活跃用户' },
];

const filterFirstCurrency = status => {
    switch (status) {
        case 0: 
            return 'CNY';
        case 1:
            return 'USDT';
        case 2:
            return 'USD';
        default: return '未知';
    }
}

const MenuItems = ({ record, handleAddPay, handleChangeActive }) => {
    const arr = ['新增充值', record.activeStatus === 0 ? '转为非活跃用户' : '转为活跃用户'];
    const itemClickEvent = (record, item) => {
        switch (item) {
            case '新增充值':
                handleAddPay(record)
                return false;
            case '转为非活跃用户':
                handleChangeActive(record)
                return false;
            case '转为活跃用户':
                handleChangeActive(record)
                return false;
            default: return false;
        }
    }
    const Node = (
        <Menu>
            {
                arr.map((item, index) => (
                    <Menu.Item key={index} onClick={() => itemClickEvent(record, item)}>
                        <span>{item}</span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Dropdown overlay={Node} trigger={['click']} placement="topCenter" getPopupContainer={() => document.getElementById("userList")}>
                <SettingOutlined
                    style={{ fontSize: 18, color: '#4895EB' }}
                    onClick={e => { e.stopPropagation() }} />
            </Dropdown>
        </div>
    );
}

const UserList = (props) => {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 16, span: 8 },
    };
    const columns = [
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 120,
            render: (text, record) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                {record.activeStatus === 0 ? <img style={{ position: 'absolute', top: '50%', left: 8, width: '20px', transform: 'translateY(-50%)' }} src={require('../../assets/img/fire.png')} alt='' /> : null}
                <span>{record.id}</span>
            </div>
        },
        {
            title: '币印账号',
            dataIndex: 'poolinAccount',
            key: 'poolinAccount',
            align: 'center',
            width: 200
        },
        {
            title: '用户姓名',
            dataIndex: 'nick',
            key: 'nick',
            align: 'center',
            width: 180
        },
        {
            title: '账户余额(CNY)',
            dataIndex: 'cnyBalance',
            key: 'cnyBalance',
            align: 'center',
            width: 160,
            render: text => text < 0 ? <span style={{ color: '#f56d64' }}>{text}</span> : text,
        },
        {
            title: '账户余额(USD)',
            dataIndex: 'usdBalance',
            key: 'usdBalance',
            align: 'center',
            width: 160,
            render: text => text < 0 ? <span style={{ color: '#f56d64' }}>{text}</span> : text,
        },
        {
            title: '优先扣费账户',
            dataIndex: 'firstCurrency',
            key: 'firstCurrency',
            align: 'center',
            render: text => filterFirstCurrency(text),
            width: 160,
        },
        {
            title: '操作',
            dataIndex: 'user',
            key: 'user',
            align: 'center',
            fixed: 'right',
            width: 180,
            render: (text, record) => <div onClick={event => event.stopPropagation()} style={{ width: '100%', height: '100%', padding: '16px 16px' }}>
                {/* <Button onClick={() => handleToDetail(record)} type="link">查看详情</Button> */}
                {/* <Button onClick={(event) => handleAddPay(record, event)} type="link">新增充值</Button> */}
                <MenuItems record={record} handleAddPay={e => handleAddPay(e, record)} handleChangeActive={() => handleChangeActive(record)} />
            </div>
        }
    ]
    const [dataSource, setDataSource] = useState([])
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 30,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    const [searchValue, setSearchValue] = useState(null);
    const [activeStatus, setActiveStatus] = useState(99);
    const [modalShow, setModalShow] = useState(false);
    const [currentPopupData, setCurrentPopupData] = useState({});

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    const handleChange = event => { // 改变值
        event.persist();
        let { value } = event.target;
        setSearchValue(value)
    }

    const handleSearch = (value) => { // 搜索
        console.log(value)
        const { current } = pagination;
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 });
            return false;
        }
        if (value) getList()
    }

    const handleAddPay = (record) => {
        setCurrentPopupData({ ...record });
        setModalShow(true);
    }

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let params = `?pageNum=${pagination.current}&pageSize=${pagination.pageSize}&like=${searchValue || ''}&activeStatus=${activeStatus}`;
        let result = await Api.manage.customQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            setDataSource(result.data);
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleToDetail = (record) => {
        const { history } = props;
        sessionStorage.removeItem('currentKey');
        history.push('detail', record)
    }

    const handleCancel = () => {
        setModalShow(false);
        setCurrentPopupData({});
    }

    const handleChangeActive = async record => { // 转为活跃状态或 非活跃状态
        const { uid, activeStatus } = record;
        const params = {
            uid,
            activeStatus: activeStatus === 0 ? 1 : 0
        };
        let res = await Api.manage.customChangeActive(params);
        if (res.errorCode === 0) {
            message.success('转换成功');
            const { current } = pagination;
            if (current !== 1) {
                setPagination({ ...pagination, current: 1 })
                return false;
            }
            setModalShow(false);
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }

    const handleConfirm = values => {
        confirm({
            title: `请确认充值信息`,
            content: `确认给${currentPopupData.nick}充值${values.num}${values.currency === 0 ? 'RMB' : 'USD'}吗？`,
            confirmText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    ...values,
                    ...currentPopupData,
                    operator: JSON.parse(localStorage.getItem('userInfo')).uid || ''
                };
                let res = await Api.manage.customRecharge(params);
                if (res.errorCode === 0) {
                    message.success('充值成功');
                    const { current } = pagination;
                    if (current !== 1) {
                        setPagination({ ...pagination, current: 1 })
                        return false;
                    }
                    setModalShow(false);
                    getList();
                } else {
                    message.error(res.errorMsg);
                }
            }
        })
    }

    const handleActiveStatusChange = val => { // 活跃用户 的变化
        setActiveStatus(val);
        const { current } = pagination;
        if (current > 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [pagination.current, activeStatus])

    useEffect(() => {
        if (searchValue === '') getList()
        // eslint-disable-next-line
    }, [searchValue])

    return (
        <div id="userList" className="user_list">
            <header>
                <Search
                    allowClear
                    value={searchValue}
                    style={{ width: 300 }}
                    placeholder={'请输入用户ID或用户姓名'}
                    onSearch={handleSearch}
                    onChange={handleChange} />
                <Select
                    style={{ marginRight: 20, width: 160 }}
                    value={activeStatus}
                    onChange={handleActiveStatusChange}>
                    {
                        ActiveStatusArray.map(item => (
                            <Option value={item.id} key={item.id}>{item.name}</Option>
                        ))
                    }
                </Select>
            </header>
            <Table
                locale={{ emptyText: '暂无数据' }}
                loading={tableLoading}
                dataSource={dataSource}
                columns={columns}
                tableLayout="fixed"
                rowKey='uid'
                scroll={{ x: 'auto' }}
                onRow={record => {
                    return {
                        onClick: event => { handleToDetail(record) }
                    }
                }}
                pagination={pagination}
                onChange={handleTableChange} />
            <ModalComponent
                title={'新增充值'}
                showFooter={false}
                size={477}
                closable={true}
                visible={modalShow}
                modalContentClass="addPayModal"
                onClick={() => setModalShow(false)}
            >
                <Form
                    {...layout}
                    form={form}
                    ref={formRef}
                    name="register"
                    onFinish={handleConfirm}
                    scrollToFirstError
                >
                    <Form.Item
                        name="currency"
                        label={'充值账户'}
                        rules={[
                            {
                                required: true,
                                message: '充值账户是必选的',
                            },
                        ]}
                    >
                        <Select placeholder={'USD/CNY'} >
                            <Option disabled={currentPopupData.usdOpened === 0} value={2}>USD</Option>
                            <Option disabled={currentPopupData.cnyOpened === 0} value={0}>CNY</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="num"
                        label={'充值金额'}
                        rules={[
                            {
                                required: true,
                                message: '充值金额是必填的',
                            },
                            {
                                pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                message: '最多输入两位小数',
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder={'请输入充值金额'} min={0} />
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label={'备注'}
                    >
                        <Input placeholder={'请输入备注'} maxLength={40} />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" onClick={handleCancel}>
                            取消
                        </Button>
                        <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default withRouter(UserList);