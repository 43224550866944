import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Table, message, DatePicker, Input, InputNumber, Button, Modal, Menu, Dropdown, Select, Tooltip } from 'antd';
import { CheckCircleOutlined, EditOutlined, SettingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Api from '../../services/api';
import './index.less';

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

const disabledDate = current => {
    return current && current + 86400000 >= moment().endOf('day');
}

const ActiveStatusArray = [
    { id: 99, name: '全部用户' },
    { id: 0, name: '活跃用户' },
    { id: 1, name: '不活跃用户' },
];

const deductStatusArray = [
    { id: 'all', name: '全部扣费状态' },
    { id: 0, name: '未扣费' },
    { id: 1, name: '已扣费' }
];

const switchArray = ['切换CNY账户', '切换USD账户'];

const currAccountDisabled = (record, item) => { // 判断当前切换的账户是否已经开启并且可以切换
    if (item === '切换CNY账户') {
        return record.cnyOpened === 0 ? true : false;
    } else {
        return record.usdOpened === 0 ? true : false;
    }
}

const MenuItems = ({ record, handleOperator }) => {
    const arr = ['确认扣费'];
    const Node = (
        <Menu>
            {
                arr.map((item, index) => (
                    <Menu.Item key={index} disabled={record.deductStatus || record.charge === 0} onClick={() => handleOperator({ ...record, type: item })}>
                        <span>{item}</span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Dropdown disabled={record.deductStatus === 1} overlay={Node} trigger={['click']} placement="topCenter" getPopupContainer={() => document.getElementById("electricPrice")}>
                <SettingOutlined
                    style={{ fontSize: 18, color: record.deductStatus === 1 ? 'rgba(0,0,0,.25)' : '#4895EB' }}
                    onClick={e => { e.stopPropagation() }} />
            </Dropdown>
        </div>
    );
}

const ElectricPrice = () => {
    const columns = [
        {
            title: '用户ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text, record) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                {record.activeStatus === 0 ? <img style={{ position: 'absolute', top: '50%', left: -8, width: '20px', transform: 'translateY(-50%)' }} src={require('../../assets/img/fire.png')} alt='' /> : null}
                <span>{record.id}</span>
            </div>
        },
        {
            title: '币印账号',
            dataIndex: 'poolinAccount',
            key: 'poolinAccount',
            align: 'center',
        },
        {
            title: '用户姓名',
            dataIndex: 'nick',
            key: 'nick',
            align: 'center'
        },
        {
            title: 'PMP用户名',
            dataIndex: 'pmpNick',
            key: 'pmpNick',
            align: 'center'
        },
        {
            title: '扣费状态',
            dataIndex: 'deductStatus',
            key: 'deductStatus',
            align: 'center',
            render: text => <span style={{ color: text === 0 ? 'rgb(245, 109, 100)' : '' }}>
                {text === 0 ? '未扣费' : '已扣费'}
            </span>
        },
        {
            title: () => {
                return <Tooltip
                    title={() => {
                        return (
                            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    src={require('../../assets/img/flag.png')}
                                    alt=""
                                    style={{ width: 16 }}
                                />
                                <span style={{ marginLeft: 2 }}>表示优先扣费账户</span>
                            </span>
                        )
                    }}
                >
                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        扣费账户
                        <InfoCircleOutlined
                            style={{
                                marginLeft: 4,
                                fontSize: 16,
                                color: '#faad14'
                            }} />
                    </span>
                </Tooltip>
            },
            dataIndex: 'currency',
            key: 'currency',
            align: 'center',
            render: (text, record) =>
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ width: 20 }}>{
                    record.firstCurrency === record.currency
                        ?
                        <img src={require('../../assets/img/flag.png')} style={{ width: 20 }} alt="" />
                        :
                        null
                }</span><span style={{ marginLeft: 6, width: 40, textAlign: 'right' }}>{filterAccountOpened(record)}</span></span>,
        },
        {
            title: '电费账单',
            dataIndex: 'charge',
            key: 'charge',
            align: 'center',
            render: (text, record, index) => {
                return rowId === record.id ?
                    <>
                        {
                            record.currency === 0
                                ?
                                <InputNumber
                                    style={{ width: 110 }}
                                    value={record.cnyCharge}
                                    min={0}
                                    onChange={(event) => { handleElectricOrderChange(event, index, record) }}
                                />
                                :
                                <InputNumber
                                    style={{ width: 110 }}
                                    value={record.usdCharge}
                                    min={0}
                                    onChange={(event) => { handleElectricOrderChange(event, index, record) }}
                                />

                        }
                        <CheckCircleOutlined
                            style={{ marginLeft: 10, fontSize: 18, color: '#4895EB' }}
                            onClick={() => handleConfirm(record)}
                        />
                    </>
                    :
                    <span>
                        { record.electricityCurrency === 0 ? '￥' : '$' }
                        {record.currency === 0 ? record.cnyCharge : record.usdCharge}
                        <EditOutlined
                            style={{ marginLeft: 8, color: (record.deductStatus || record.electricityCurrency !== record.currency) ? '#ccc' : '' }}
                            onClick={() => handleSwitchStatus(record)}
                        />
                    </span>
            },
        },
        {
            title: '账户余额',
            dataIndex: 'cnyBalance',
            key: 'cnyBalance',
            align: 'center',
            render: (text, record) => <span style={{ color: Number(record.currency === 0 ? text : record.usdBalance).toFixed(2) < record.charge ? '#f56d64' : '' }}>{Number(record.currency === 0 ? text : record.usdBalance).toFixed(2)}</span>
        },
        {
            title: '操作',
            dataIndex: 'user',
            key: 'user',
            width: 160,
            align: 'center',
            fixed: 'right',
            render: (text, record, index) => <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Dropdown overlay={<Menu>
                    {
                        switchArray.map((item, i) => (
                            <Menu.Item key={i} record={record} disabled={currAccountDisabled(record, item)} onClick={() => handleOperation({ ...record, type: item, index })}>
                                <span>{item}</span>
                            </Menu.Item>
                        ))
                    }
                </Menu>} disabled={record.deductStatus === 1 || (record.cnyOpened === 0 || record.usdOpened === 0)} trigger={['hover']} placement="topCenter" getPopupContainer={() => document.getElementById("electricPrice")}>
                    <Button type="link">切换账户</Button>
                </Dropdown>
                <MenuItems record={{ ...record, index }} handleOperator={e => handleOperator(e, record)} />
            </div>
        }
    ]
    const [dataSource, setDataSource] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [dateObj, setDateObj] = useState({
        date: null,
        dateString: '',
    });
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 20,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    const [rowId, setRowId] = useState('') // 当前行修改备注
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [deductStatus, setDeductStatus] = useState('all'); // 扣费状态筛选排序
    const [activeStatus, setActiveStatus] = useState(99); // 全部

    const filterAccountOpened = (record) => {
        const { cnyOpened, usdOpened, currency } = record;
        if (cnyOpened === 1 && usdOpened === 1) { // 两种都在可切换
            return currency === 0 ? 'CNY' : 'USD'
        } else {
            if (cnyOpened === 1) {
                return 'CNY'
            } else  if (usdOpened === 1) {
                return 'USD';
            }
        }
    }

    const handleOperator = record => {
        const list = [{ uid: record.uid, currency: record.currency, amount: record.charge }]
        confirm({
            title: '提示',
            content: '确定已完成核对并扣除所选的电费吗?',
            okText: '确认',
            cancelText: '取消',
            onOk: () => electricityDailyDeduct(list)
        })
    }

    const handleConfirmPay = () => {
        let list = selectedRowKeys.map(item => {
            let obj = dataSource.filter(temp => temp.uid === item)[0]
            return ({ uid: obj.uid, currency: obj.currency, amount: obj.charge })
        });
        confirm({
            title: '提示',
            content: '确定已完成核对并扣除所选的电费吗?',
            okText: '确认',
            cancelText: '取消',
            onOk: () => electricityDailyDeduct(list)
        })
    }

    const electricityDailyDeduct = async list => {
        const params = {
            date: dateObj.dateString.replace(/\//g, '-'),
            operator: JSON.parse(localStorage.getItem('userInfo')).uid || '',
            list
        }
        let res = await Api.manage.customElectricityDailyDeduct(params);
        if (res.errorCode === 0) {
            message.success('扣费成功');
            const { current } = pagination;
            if (current > 1) {
                setPagination({ ...pagination, current: 1 })
                setSelectedRowKeys([])
                return false;
            }
            getList();
        } else {
            message.error(res.errorMsg)
        }
    }

    const handleOperation = (message) => {
        const { type, index } = message;
        console.log(index, type, '------')
        let currency = type === '切换CNY账户' ? 0 : 2;
        let data = dataSource;
        data[index].currency = currency;
        setRowId('')
        setDataSource([...data])
    }

    const handleSwitchStatus = (record) => {
        const { id, deductStatus, currency, electricityCurrency } = record;
        if (currency !== electricityCurrency) return message.error('电费账单币种和当前币种不一致')
        if (deductStatus) return false;
        setRowId(id)
    }

    const handleElectricOrderChange = (value, index, record) => {
        let data = dataSource
        data[index][record.currency === 0 ? 'cnyCharge' : 'usdCharge'] = value;
        setDataSource([...data])
    }

    const handleDateChange = (date, dateString) => {
        setRowId('')
        setDateObj({ date, dateString })
        if (pagination.current > 1) {
            setPagination({ ...pagination, current: 1 })
        }
    }

    const handleConfirm = async (record) => {
        const { uid, cnyCharge, usdCharge, currency } = record;
        const params = {
            amount: currency === 0 ? cnyCharge : usdCharge,
            uid,
            currency,
            date: dateObj.dateString.replace(/\//g, '-')
        };
        let res = await Api.manage.customElectricityDailyModify(params);
        if (res.errorCode === 0) {
            message.success('修改成功');
            setRowId('')
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }

    const getList = async () => { // 获取数据列表
        const { dateString } = dateObj;
        setTableLoading(true);
        setSelectedRowKeys([])
        setDataSource([])
        let params = `?date=${dateString.replace(/\//g, '-')}&pageNum=${pagination.current}&pageSize=${pagination.pageSize}&like=${searchValue}&deductStatus=${deductStatus === 'all' ? '' : deductStatus}&activeStatus=${activeStatus}`;
        let result = await Api.manage.customElectricityDailyQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            let list = result.data;
            if (list.length > 0) {
                list = list.map(item => ({
                    ...item,
                    cnyCharge: item.electricityCurrency === 0 ? item.charge : 0,
                    usdCharge: item.electricityCurrency === 2 ? item.charge : 0,
                    currency: item.electricityCurrency,
                }))
            }
            setDataSource(list);
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    const handleChange = event => { // 改变值
        setRowId('')
        event.persist();
        let { value } = event.target;
        setSearchValue(value)
    }

    const handleSearch = (val) => { // 搜索
        const { current } = pagination;
        if (current > 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
        if (val) getList();
    }

    const handleActiveStatusChange = val => { // 活跃用户 的变化
        setActiveStatus(val);
        const { current } = pagination;
        if (current > 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleSelectedChange = selectedRowKeys => {
        setRowId('')
        setSelectedRowKeys(selectedRowKeys);
    }

    const handleDeductStatusChange = (val) => {
        setRowId('')
        setDeductStatus(val);
    }

    useEffect(() => {
        let date = new Date().getTime() - 86400000;
        let year = new Date(date).getFullYear();
        let month = new Date(date).getMonth() + 1 > 9 ? new Date(date).getMonth() + 1 : `0${new Date(date).getMonth() + 1}`;
        let hours = new Date(date).getHours();
        let day = '';
        if (hours >= 8) {
            day = new Date(date).getDate() > 9 ? new Date(date).getDate() : `0${new Date(date).getDate()}`;;
        } else {
            year = new Date(date - 86400000).getFullYear();
            month = new Date(date - 86400000).getMonth() + 1 > 9 ? new Date(date - 86400000).getMonth() + 1 : `0${new Date(date - 86400000).getMonth() + 1}`;
            day = new Date(date - 86400000).getDate() > 9 ? new Date(date - 86400000).getDate() : `0${new Date(date - 86400000).getDate()}`;
        }
        setDateObj({ date: moment(`${year}/${month}/${day}`), dateString: `${year}/${month}/${day}` })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!searchValue && dateObj.dateString !== "") getList();
        // eslint-disable-next-line
    }, [searchValue])

    useEffect(() => {
        if (dateObj.dateString !== "") {
            getList();
        }
        // eslint-disable-next-line
    }, [dateObj.dateString, pagination.current, deductStatus, activeStatus])

    return (
        <div id="electricPrice" className="electric_price">
            <header>
                <div>
                    <DatePicker
                        allowClear={false}
                        style={{ width: 200 }}
                        value={dateObj.date}
                        onChange={handleDateChange}
                        disabledDate={disabledDate} />
                </div>
                <div>
                    <Select
                        style={{ marginRight: 20, width: 160 }}
                        value={activeStatus}
                        onChange={handleActiveStatusChange}>
                        {
                            ActiveStatusArray.map(item => (
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            ))
                        }
                    </Select>
                    <Select
                        style={{ width: 160 }}
                        value={deductStatus}
                        onChange={handleDeductStatusChange}>
                        {
                            deductStatusArray.map(item => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))
                        }
                    </Select>
                    <Search
                        allowClear
                        value={searchValue}
                        style={{ marginLeft: 20, width: 300 }}
                        placeholder={'请输入用户ID或用户姓名'}
                        onSearch={handleSearch}
                        onChange={handleChange} />
                </div>
            </header>
            <main>
                <div style={{ padding: '30px 20px 10px' }}>
                    <Button disabled={selectedRowKeys.length === 0} type="primary" size="middle" onClick={handleConfirmPay}>确认扣费</Button>
                </div>
                <Table
                    locale={{ emptyText: '暂无数据' }}
                    loading={tableLoading}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: handleSelectedChange,
                        getCheckboxProps: record => {
                            return { disabled: record.deductStatus }
                        }
                    }}
                    dataSource={dataSource}
                    columns={columns}
                    tableLayout="fixed"
                    rowKey='uid'
                    scroll={{ x: 'auto' }}
                    pagination={pagination}
                    onChange={handleTableChange} />
            </main>
        </div>
    )
}

export default withRouter(ElectricPrice);