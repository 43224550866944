import axios from 'axios';
import { message } from 'antd';
import * as combineReducers from '../store/action';
// import qs from "qs";

// axios.defaults.baseURL = '/';
axios.defaults.withCredentials = true;
axios.defaults.timeout = 100000;

axios.interceptors.request.use(
    config => {
        config.headers = {
            "Content-Type" : "application/json",
            // "CF-Access-Client-Id": "86e43adde97a6ac70ffbfc0507faa2ea.access",
            // "CF-Access-Client-Secret": "63005ae6c701b74bfaaf8ea12521c8163daf7aab841f4551ea5cdb30264d961b"
        }
        return config
    },
    err => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(response => {
    // 在这里你可以判断后台返回数据携带的请求码
   if (response.status === 200 || response.stauts === '200') {
     return response.data
   }else {
     // 非200请求抱错
     throw Error(response.data.msg || '服务异常')
   }
})


const request = {
    get(url, params){
        return new Promise((resolve, reject) =>{        
            axios.get(`${url}${params !== undefined ? params : ''}`).then(res => {
                resolve(res);
            }).catch(err =>{
                let data = err.response;
                if (data?.status === 401) {
                    // 未登录状态
                    combineReducers.logout();
                    window.location.href = '/';
                    return false;
                }
                message.error('服务异常，请检查网络');
                reject(err.data)        
        })    
    });},
    /** 
    * post方法，对应post请求 
    * @param {String} url [请求的url地址] 
    * @param {Object} params [请求时携带的参数] 
    */
    post(url, params) {
        return new Promise((resolve, reject) => {
             axios.post(url, JSON.stringify(params))
            .then(res => {
                resolve(res);
            })
            .catch(err =>{
                let data = err.response;

                if (data?.status === 401) {
                    // 未登录状态
                    window.location.href = '/';
                    combineReducers.logout();
                    return false;
                }
                message.error('服务异常，请检查网络');
                reject(err.data)
            })
        });
    }
};
 


export default request;