// import _ from 'lodash' 暂时不知道这个 _是什么东西，用到了在问一下。
import moment from 'moment';

export function numberFormat(number, decimals, decPoint, thousandsSep) {
  decimals = isNaN(decimals) ? 2 : Math.abs(decimals)
  decPoint = (decPoint === undefined) ? '.' : decPoint
  thousandsSep = (thousandsSep === undefined) ? ',' : thousandsSep

  let sign = number < 0 ? '-' : ''
  number = Math.abs(+number || 0)

  let intPart = parseInt(number.toFixed(decimals), 10) + ''
  let j = intPart.length > 3 ? intPart.length % 3 : 0

  return sign + (j ? intPart.substr(0, j) + thousandsSep : '') + intPart.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandsSep) + (decimals ? decPoint + Math.abs(number - intPart).toFixed(decimals).slice(2) : '')
}

export function numberUnitFormat(size = 0, dec = 2) {
  // @TODO: change to >> compare
  if (size >= Math.pow(10, 27)) {
    return `${size}`;
    // return {
    //   size,
    //   unit: ''
    // }
  } else {
    const unit = [' ', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    let pos = 0

    while (size >= 1000) {
      size /= 1000
      pos++
    };
    // return `${numberFormat(size, dec)} ${unit[pos]}`;
    return {
      size: numberFormat(size, dec),
      unit: unit[pos]
    }
  }
}
export function currentNumberUnit(size = 0) {
  if (size >= Math.pow(10, 27)) {
    return `${size}`;
    // return {
    //   size,
    //   unit: ''
    // }
  } else {
    let pos = 0

    while (size >= 1000) {
      size /= 1000
      pos+= 3
    };
    return pos;
  }
}

export function numberFormatGivenUnit(num, unit, dec = 2) {
  let size = 0
  const HASHRATE_UNIT_MAP = {
    H: 0,
    K: 3,
    M: 6,
    G: 9,
    T: 12,
    P: 15,
    E: 18
  }

  if (unit === '' || unit === ' ') {
    size = 0
  } else {
    size = HASHRATE_UNIT_MAP[unit]
  }

  return (num / Math.pow(10, size)).toFixed(dec)
}

export function formatCurrency(amount, unit = ' BTC', splitColor = true, flag = false, decimals = 4) {
  let val = numberFormat(amount, 8)
  decimals *= -1
  if (flag) {
    val = amount > 0 ? `+ ${val}` : `- ${val.substr(1)}`
  }
  if (!unit) {
    unit = ''
  }
  if (splitColor) {
    let valLeft = val.slice(0, decimals)
    let valRight = val.substr(decimals)

    return `${valLeft}<span class="text-muted">${valRight}</span>${unit}`
  } else {
    return `${val}${unit}`
  }
}

export function formatUTCDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getUTCFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getUTCMonth() + 1,
    'd+': date.getUTCDate(),
    'h+': date.getUTCHours(),
    'm+': date.getUTCMinutes(),
    's+': date.getUTCSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }
  return fmt
}

export function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function toRelativeTime(timestamp, displayOne = false) {
  if (!timestamp) {
    return ''
  }

  let diff = timestamp

  let d = Math.floor(diff / 86400),
    h = Math.floor((diff / 86400 - d) * 24),
    m = Math.floor(((diff / 86400 - d) * 24 - h) * 60),
    s = Math.floor(diff % 60)

  if (diff >= 86400) {
    if (displayOne) {
      return `${d} ${'天'}`
    } else {
      return `${d} ${'天'} ${h} ${'小时'}`
    }
  } else if (diff >= 3600) {
    if (displayOne) {
      return `${h} ${'小时'}`
    } else {
      return `${h} ${'小时'} ${m} ${'分'}`
    }
  } else if (diff >= 60) {
    if (displayOne) {
      return `${m} ${'分'}`
    } else {
      return `${m} ${'分'} ${s} ${'秒'}`
    }
  } else {
    return `${s} ${'秒'}`
  }
}

export function remove(arr, index) {
  return arr.reduce((prev, x, i) => prev.concat(i !== index ? [x] : []), [])
}

/*
* 解析from, to格式为IP数组
*
* 输入:
* [
*   { from: '192.168.0.1', to: '192.168.0.20' },
*   { from: '192.168.1.1', to: '192.168.0.20' },
* ]
*
* 返回
*
* [去重后的全部IP数组，未去重的全部IP数组]
*
* */
// export function parseIPRange (ipRangeList) {
//   let result = []
//   let intIPList = []
//   _.each(ipRangeList, item => {
//     // from, to 转换成int
//     let from = item['from'].split('.')
//     from = (+from[3]) * Math.pow(256, 0) + (+from[2]) * Math.pow(256, 1) + (+from[1]) * Math.pow(256, 2) + (+from[0]) * Math.pow(256, 3)
//     let to = item['to'].split('.')
//     to = (+to[3]) * Math.pow(256, 0) + (+to[2]) * Math.pow(256, 1) + (+to[1]) * Math.pow(256, 2) + (+to[0]) * Math.pow(256, 3)

//     let ipRange = _.range(from, to + 1, 1)
//     intIPList = intIPList.concat(ipRange)
//   })

//   // int转换成x.x.x.x
//   _.each(intIPList, item => {
//     let hex = item.toString(16)
//     let segList = hex.match(/.{1,2}/g)
//     let tip = ''
//     _.each(segList, i => {
//       let seg = `${parseInt('0x' + i)}`
//       tip = tip + seg + '.'
//     })
//     tip = `${tip.slice(0, -1)}`
//     result.push(tip)
//   })

//   result.sort((a, b) => {
//     return a.split('.')[0] - b.split('.')[0] || a.split('.')[1] - b.split('.')[1] || a.split('.')[2] - b.split('.')[2] || a.split('.')[3] - b.split('.')[3]
//   })

//   let unUniq = result.slice()
//   let uniq = _.uniq(unUniq)

//   return [uniq, unUniq]
// }

export function filterMineListTemperature(val) {
  // 过滤矿机列表页面表格的温度数据
  if (!Array.isArray(val)) return false;
  let arr = JSON.parse(JSON.stringify(val));
  arr.forEach((item, index, arr) => {
    if (!item) {
      arr[index] = "-";
    }
  })
  return arr.join('/');
}

export function isRepeatArr(arr) {
  var hash = {};
  for (var i in arr) {
    if (hash[arr[i]]) return true;
    hash[arr[i]] = true;
  }
  return false;

};

export function filterData(arr) {
  if (arr.length === 0) return [];
  let newArr = [];
  arr.length > 0 && arr.forEach((item, index) => {
    let obj = {
      id: index + 1,
      name: item.name,
      hahstate: item.totalRate,
      list: [],
      xAxisList: []
    };
    item.totalRateHistory.forEach(temp => {
      obj.list.push(temp.rate);
      obj.xAxisList.push(temp.date);
    })
    newArr.push(obj);
  });
  return newArr;
}

export function compare(property) { // 数组排序
  return function(a,b){
      let value1 = a[property];
      let value2 = b[property];
      return value1 - value2;
  }
}
export function reverseCompare(property) { // 数组排序 大到小
  return function(a,b){
      let value1 = a[property];
      let value2 = b[property];
      return value2 - value1;
  }
}

export function newFormatUTCDate(string) {
  if (typeof(string) !== 'string') return false;
  let str = string.replace("-","/");
  let zoneTime = new Date().getTimezoneOffset() * 60 * 1000;
  let time = new Date(new Date(str).getTime());
  let date =  new Date(time - zoneTime);
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

// 数组排序,0-9,a-z,A-Z
export function arrSortMinToMax(arr, type) {
  var arr1 = arr.filter(item=>!isChinese(item[type][0]));  //首字母非中文
  var arr2 = arr.filter(item=>isChinese(item[type][0]));  //首字母是中文
  arr1.sort(function (a, b) { return b[type].charCodeAt() - a[type].charCodeAt() }).reverse();  // 数字字母排序(降序)
  sortChinese(arr2, type);  // 中文排序(降序)
  return arr1.concat(arr2);
}
// 中文拼音排序(降序)
export function sortChinese(arr, dataLeven) { // 参数：arr 排序的数组; dataLeven 数组内的需要比较的元素属性   /* 获取数组元素内需要比较的值 */
   function getValue(option) { // 参数： option 数组元素
       if (!dataLeven) return option
       var data = option
       // eslint-disable-next-line
       dataLeven.split('.').filter(function (item) { data = data[item]})
       return data + ''
  }
  arr.sort(function (item1, item2) { return getValue(item1).localeCompare(getValue(item2), 'zh-CN');
})
}

// 检测是否为中文，true表示是中文，false表示非中文
export function isChinese(str) {
    if(/^[\u3220-\uFA29]+$/.test(str)){
        return true;
    }else{
        return false;
    }
}

export function errorMsg(code) {
  switch (code) {
    case 1: 
      return '缺少uid';
    case 1001:
      return '参数不正确';
    case 1002:
      return '查询错误';
    case 1003:
      return '插入错误';
    case 1004:
      return '哈希错误';
    case 1005:
      return '数据重复';
    case 1006:
      return '更新错误';
    case 1007:
      return '删除错误';
    case 2000:
      return '还存在使用配置的矿机';
    case 2001:
      return '不存在的矿机配置ID';
    case 2002:
      return '修改矿机配置失败';
    default: return '未知的错误，请稍后再试';
  }
}

export function getUrlToken(name, str) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return  decodeURIComponent(r[2]); return null;
}
