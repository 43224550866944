import React, { Component } from 'react'
import { Modal, Spin, Button } from 'antd'
import PropTypes from 'prop-types'
import './index.less';

const noop = () => {}

/**
 * modal 对话框
 */
class ModalComponent extends Component {
	static propTypes = {
		visible: PropTypes.bool,
		loading: PropTypes.bool,
		title: PropTypes.any,
		size: PropTypes.number, // 500
		showFooter: PropTypes.bool,
		footer: PropTypes.element,
		closable: PropTypes.bool,
		showCancelButton: PropTypes.bool,
		showSaveButton: PropTypes.bool,
		onClick: PropTypes.func,
		cancelButtonText: PropTypes.string,
		saveButtonText: PropTypes.string,
		modalContentClass: PropTypes.string,
		destroyOnClose: PropTypes.bool,
		maskClosable: PropTypes.bool,
		btnLoading: PropTypes.bool,
	}

	static defaultProps = {
		loading: false,
		size: 550,
		visible: true,
		showFooter: true,
		title: 'modal 标题',
		closable: true,
		showCancelButton: true,
		showSaveButton: true,
		cancelButtonText: '取消',
		saveButtonText: '确定',
		onClick: noop,
		modalContentClass: '',
		destroyOnClose: false,
		maskClosable: true,
		btnLoading: false,
	}

	/**
	 * 取消/关闭/成功 按钮点击回调
	 * @param flag
	 */
	handleClickFooterButton = flag => {
		const { onClick } = this.props
		onClick && onClick(flag)
	}

	handleGetFooter = () => {
		const { footer, showCancelButton, showSaveButton, showFooter, cancelButtonText, saveButtonText, btnLoading } = this.props

		if (!showFooter) return null

		if (footer) {
			return footer
		} else {
			const footerArr = []

			if (showCancelButton) {
				footerArr.push(
					<Button key="cancel" size="default" onClick={this.handleClickFooterButton.bind(null, false)}>
						{cancelButtonText}
					</Button>
				)
			}

			if (showSaveButton) {
				footerArr.push(
					<Button
						key="save"
						type="primary"
						size="default"
						loading={btnLoading}
						onClick={() => {
							if (this.props.loading) return false
							// console.log(this.props.loading)
							this.handleClickFooterButton.call(null, true)
						}}
					>
						{saveButtonText}
					</Button>
				)
			}

			return footerArr
		}
	}

	render() {
		const { modalContentClass, loading, size, children, title, closable, visible, destroyOnClose, maskClosable, ...otherProps } = this.props

		return (
			<Modal
				{...otherProps}
				width={size}
				title={title}
				visible={visible}
				closable={closable}
				destroyOnClose={destroyOnClose}
				maskClosable={maskClosable}
				onCancel={this.handleClickFooterButton.bind(null, false)}
				footer={this.handleGetFooter()}
			>
				<Spin spinning={loading}>
					<div className={modalContentClass}>{children}</div>
				</Spin>
			</Modal>
		)
	}
}

export default ModalComponent
