import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import LoginComponent from './pages/Login/login';
import AppRoutes from './routes/router';
import SecondNav from './components/secondNav';
import HeaderTitleNav from './components/header/headerTitleNav';
import zhCN from 'antd/lib/locale/zh_CN';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import './App.less';

const allRoutes = [
  ...AppRoutes,
]

const { Content, Sider } = Layout;

class CollapsedIcon extends React.Component {
  render() {
    return this.props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
  }
}

class App extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    this.setState({
      collapsed
    }, () => {
      if (collapsed) {
        setTimeout(() => {
          let node = document.getElementsByClassName("ant-menu-sub")[0];
          if (node) node.classList.add("ant-menu-hidden");
        }, 1000);
      } else {
        let node = document.getElementsByClassName("ant-menu-sub")[0];
        if (node) {
          node.classList.remove("ant-menu-hidden");
          node.classList.add("ant-menu-hidden");
        }
      }

    });
  };
  componentDidCatch(error, info) {
    window.location.reload();
  }


  getAllRouter(config) {
    return config
      .map(route => {
        if (route.children) {
          return this.getAllRouter(route.children)
        } else {
          return (
            <Route
              key={route.key}
              path={route.path}
              component={
                route.component
              }
              exact={!!route.exact}
            />
          )
        }
      })
  }

  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Layout style={{ height: '100vh', background: 'rgb(244, 246, 250)' }}>
          {
            localStorage.getItem("userInfo") !== null ?
              <Router>
                <Layout>
                  <Sider
                    style={{
                      overflow: 'auto',
                      height: '100vh',
                      position: 'fixed',
                      left: 0,
                      zIndex: 100000,
                    }}
                    collapsible={true}
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    trigger={<CollapsedIcon collapsed={this.state.collapsed} />}
                  >
                    <div className="slider-container">
                      <SecondNav collapsed={this.state.collapsed} />
                    </div>
                  </Sider>
                  <Layout>
                    <Content>
                      <div style={{ marginLeft: 200, background: '#F4F6FA' }}>
                        <HeaderTitleNav />
                        <div style={{ padding: 24 }}>
                          <Switch>
                            {this.getAllRouter(allRoutes)}
                            <Redirect from={'/'} to={'/user'} />
                          </Switch>
                        </div>
                      </div>
                    </Content>
                  </Layout>
                </Layout>
              </Router>
              :
              <Layout>
                <Router>
                  <Route path="/" component={LoginComponent} />
                  <Redirect from={"/"} to={"/"} />
                </Router>
              </Layout>}
        </Layout>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLogin: state.user
  }
}

App = connect(mapStateToProps)(App)

export default App;
