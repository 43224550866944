/* eslint no-undef: "off" */
/* eslint no-script-url: "off" */
import React from 'react';

import PropTypes from 'prop-types';

import { loadScript } from '../utils/loadScript'; // unloadScript

class OnePass extends React.Component {

    state = {
        nc: null,
        time: new Date().getTime()
    }
    
    ncInit = async function () {
        if (this.props.type === 'click') {
            window.NVC_Opt = {
              appkey: "FFFF0N2N0000000058C5",
              scene: "ic_login_h5",
              elements: [
                "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png",
                "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png"
              ],
              bg_back_prepared: "//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png",
              bg_front: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC",
              obj_ok: "//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png",
              bg_back_pass: "//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png",
              obj_error: "//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png",
              bg_back_fail: "//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png",
              upLang:{"cn":{
                  _ggk_guide: "请在屏幕上滑动，刮出两面盾牌",
                  _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
                  _ggk_loading: "加载中",
                  _ggk_fail: ["呀，盾牌不见了<br/>请","javascript:noCaptcha.reset()","再来一次","或","http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
                  _ggk_action_timeout: ["我等得太久啦<br/>请","javascript:noCaptcha.reset()","再来一次","或","http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
                  _ggk_net_err: ["网络实在不给力<br/>请","javascript:noCaptcha.reset()","再来一次","或","http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"],
                  _ggk_too_fast: ["您刮得太快啦<br/>请","javascript:noCaptcha.reset()","再来一次","或","http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN","反馈问题"]
                }
              }
            }
    
            await loadScript("https://g.alicdn.com/sd/nvc/1.1.112/guide.js")
            await loadScript("https://g.alicdn.com/sd/smartCaptcha/0.0.1/index.js")
            await loadScript("https://g.alicdn.com/sd/ncpc/nc.js?t=2015052012")
          }
    };

    componentDidMount() {
       this.ncInit();
       if (this.props.display) {
        if (this.props.type === 'default') {
          this.init()
        } else {
          this.initClick()
        }
      } else {
        this.state.nc && this.destory()
      }
    };

    componentWillReceiveProps(props){
        if (props.display) {
            if (props.type === 'default') {
              this.init()
            } else {
              this.initClick()
            }
          } else {
            this.state.nc && this.destory()
          }
    }

    componentWillUnmount() {
        this.destory()
        // unloadScript("https://g.alicdn.com/sd/nvc/1.1.112/guide.js")
        // unloadScript("https://g.alicdn.com/sd/smartCaptcha/0.0.1/index.js")
        // unloadScript("https://g.alicdn.com/sd/ncpc/nc.js?t=2015052012")
    };

    init = () => {
        if (this.state.nc) {
          this.state.nc.destroy();
        }
        let nc
        const nc_token = ["FFFF0N2N0000000058C5", (new Date()).getTime(), Math.random()].join(':');
        const NC_Opt =
          {
            renderTo: `#one-pass-${this.state.time}`,
            appkey: "FFFF0N2N0000000058C5",
            scene: "nc_other_h5",
            token: nc_token,
            customWidth: this.props.width,
            elementID: ["usernameID"],
            is_Opt: 0,
            language: 'cn',
            isEnabled: true,
            timeout: 3000,
            times:5,
            callback: (data) => {
              this.props.OnePassed({
                sessionId: data.csessionid,
                sig: data.sig,
                token: nc_token,
              })
              nc && nc.destroy()
            },
          }
        nc = new noCaptcha(NC_Opt)
        this.setState({
            nc
        }) 
      };

    initClick = () => {
        if (this.state.nc) {
          this.destory()
        }
        var ic = new smartCaptcha({
          renderTo: `#one-pass-${this.state.time}`,
          width: '100%',
          height: 42,
          default_txt: "点击按钮开始智能验证",
          success_txt: "验证成功",
          fail_txt: "验证失败，请在此点击按钮刷新",
          scaning_txt: "智能检测中",
          success: (data) => {
            this.props.OnePassed({
              sessionId: data.sessionId,
              sig: data.sig,
              id_token: NVC_Opt.token,
            })
            this.setState({
                nc: null
            });
            this.destory()
          },
        });
        ic.init();
        this.setState({nc: ic});
    };

    destory = () => {
        let $onePass = document.getElementById(`one-pass-${this.state.time}`,);
        let $afsWrapper = document.getElementById('SM_BTN_WRAPPER_1');
        $afsWrapper && $onePass.removeChild($afsWrapper) // 二次验证
    };

    render() {
        return (
            <div className="nc-container" id={`one-pass-${this.state.time}`} />
        )
    }
}

OnePass.propTypes = {
    display: PropTypes.bool,
    type: PropTypes.string,
    width: PropTypes.any,
};

OnePass.defaultProps = {
    display: false,
    type: 'default', // default, click
    width: 410,

};

export default OnePass;
