import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter } from 'react-router';
import { Select, Input, Table, message, Button, Form, InputNumber, Modal } from 'antd';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import TabComponent from '../../../../components/tab/index';
import RadioGroup from '../../../../components/radioGroup/index';
import ModalComponent from '../../../../components/modal';
import moment from 'moment';
import Api from '../../../../services/api';
import './index.less';

const arr = [{ name: '充值记录', key: 0 }, { name: '扣费记录', key: 1 }];
const timeArr = [{ name: '近30天', key: 0 }, { name: '全部', key: 1 }];

const { Option } = Select;
const { confirm } = Modal;

const ElectricOrder = (props) => {
    const { location } = props;
    const { uid, nick } = location.state;
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const tableContainer = useRef(null);
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 16, span: 8 },
    };
    const rechargeColumns = [
        {
            title: '充值账户',
            dataIndex: 'currency',
            key: 'currency',
            align: 'center',
            render: text => text === 0 ? 'CNY' : 'USD'
        },
        {
            title: '充值金额',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
        },
        {
            title: '账户余额',
            dataIndex: 'balance',
            key: 'balance',
            align: 'center'
        },
        {
            title: '充值时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            render: text => moment(moment.utc(text).toDate())
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            render: (text, record, index) => {
                return rowId === record.id ?
                    <>
                        <Input
                            style={{ width: 140 }}
                            value={text}
                            maxLength={40}
                            onChange={(event) => { handleRemarkChange(event, index) }}
                        />
                        <CheckCircleOutlined
                            style={{ marginLeft: 10, fontSize: 18, color: '#4895EB' }}
                            onClick={() => handleConfirm(record)}
                        />
                    </>
                    :
                    <span>
                        {text}
                        <EditOutlined
                            style={{ marginLeft: 8 }}
                            onClick={() => handleSwitchStatus(record.id)}
                        />
                    </span>
            }
        }
    ]

    const deductionColumns = [
        {
            title: '扣费账户',
            dataIndex: 'currency',
            key: 'currency',
            align: 'center',
            render: text => text === 0 ? 'CNY' : 'USD'
        },
        {
            title: '扣费类型',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: text => text === 2 ? '电费' : '其它'
        },
        {
            title: '扣费金额',
            dataIndex: 'amount',
            key: 'currency',
            align: 'center',
        },
        // {
        //     title: '扣费费率',
        //     dataIndex: 'currency',
        //     key: 'currency',
        //     align: 'center',
        //     render: text => Number(dataObj[text === 0 ? 'cnyRate' : 'usdtRate']).toFixed(4)
        // },
        {
            title: '账户余额',
            dataIndex: 'balance',
            key: 'balance',
            align: 'center'
        },
        {
            title: '扣费时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            align: 'center',
            render: text => moment(moment.utc(text).toDate())
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
        }
    ]
    const [rowId, setRowId] = useState('') // 当前行修改备注
    const [modalShow, setModalShow] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 20,
        current: 1,
        showSizeChanger: false,
        total: 0,
    });
    const [billType, setBillType] = useState(0)
    const [rangeType, setRangeType] = useState(0)
    const [currency, setCurrency] = useState(-1)
    // eslint-disable-next-line
    const [dataObj, setDataObj] = useState({
        cnyTotal: 0,
        usdtTotal: 0,
        cnyRate: 0,
        usdtRate: 0
    })
    const [tableHeight, setTableHeight] = useState(0)

    const handleAddPay = () => {
        setModalShow(true);
    }

    const handleSwitchBillType = type => {
        setBillType(Number(type))
        const { current } = pagination;
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleSwitchRangeType = event => {
        let { value } = event.target;
        const { current } = pagination;
        setRangeType(value)
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleCurrencyChange = currency => {
        setCurrency(currency);
        const { current } = pagination;
        if (current !== 1) {
            setPagination({ ...pagination, current: 1 })
            return false;
        }
    }

    const handleSwitchStatus = (rowId) => {
        setRowId(rowId)
    }

    const handleRemarkChange = (event, index) => {
        let value = event.target.value;
        let data = dataSource
        data[index].remark = value;
        setDataSource([...data])
    }

    const getList = async () => { // 获取数据列表
        setTableLoading(true);
        let beginDate = '2021-01-01', endDate = moment(new Date()).local().format('YYYY-MM-DD');
        if (rangeType === 0) { // 近30天
            beginDate = moment(new Date()).subtract(1,'months').format('YYYY-MM-DD');
        } else {
            beginDate = '2021-01-01';
        }
        let params = `?uid=${uid}&billType=${billType === 0 ? 'recharge' : 'deduction'}&beginDate=${beginDate}&endDate=${endDate}&currency=${currency !== -1 ? currency : ''}&pageNum=${pagination.current}&pageSize=${pagination.pageSize}`;
        let result = await Api.manage.customElectricityBillQuery(params);
        setTableLoading(false);
        if (result.errorCode === 0) {
            if (Object.keys(result.data).length > 0) {
                setDataSource(result.data.list);
                const { cnyDeductionTotal, cnyRechargeTotal, usdDeductionTotal, usdRechargeTotal, cnyRate, usdRate, cnyOpened, usdOpened } = result.data;
                setDataObj({ cnyDeductionTotal, cnyRechargeTotal, usdDeductionTotal, usdRechargeTotal, cnyRate, usdRate, cnyOpened, usdOpened })
            }
            setPagination({ ...pagination, total: result.total });
        } else {
            message.error(result.errorMsg);
        }
    };

    const handleConfirm = async (record) => {
        const { id, remark } = record;
        const params = { 
            id,
            remark,
        };
        let res = await Api.manage.customElectricityBillRemark(params);
        if (res.errorCode === 0) {
            message.success('修改成功');
            const { current } = pagination;
            if (current !== 1) {
                setPagination({ ...pagination, current: 1 })
                return false;
            }
            setRowId('')
            getList();
        } else {
            message.error(res.errorMsg);
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        if (page.current !== pagination.current) {
            setPagination({ ...pagination, current: page.current });
        }
    };

    const handleCancel = () => {
        setModalShow(false);
    }

    const handleFinish = values => {
        const { num, currency } = values;
        confirm({
            title: '提示',
            content: `确认给${nick}充值${num}${currency === 0 ? 'RMB' : 'USD'}吗?`,
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                const params = { 
                    ...values,
                    uid,
                    operator: JSON.parse(localStorage.getItem('userInfo')).uid || ''
                };
                let res = await Api.manage.customRecharge(params);
                if (res.errorCode === 0) {
                    message.success('充值成功');
                    const { current } = pagination;
                    if (current !== 1) {
                        setPagination({ ...pagination, current: 1 })
                        return false;
                    }
                    setModalShow(false);
                    getList();
                } else {
                    message.error(res.errorMsg);
                }
            }
        })
    }

    useEffect(() => {
        if (modalShow) {
            formRef.current.resetFields();
        }
    }, [modalShow])

    useLayoutEffect(() => {
        setTableHeight(tableContainer?.current?.offsetTop)
    }, [])

    useEffect(() => {
        getList()
        // eslint-disable-next-line 
    }, [pagination.current, rangeType, currency, billType])

    return (
        <div className="electric_order">
            {/* <header>
                <span>用户ID:</span>
                <span>{uid}</span>
                <span>用户姓名:</span>
                <span>{nick || '未知用户'}</span>
            </header> */}
            <section>
                <TabComponent arr={arr} sendParent={handleSwitchBillType} activeKey={billType} />
                <div className="desc_box">
                    <div>
                        <span>{billType === 0 ? '充值金额' : '扣费总额'}:</span>
                        <span>
                            {
                                currency !== 1 ? <><b>￥</b>
                                <span>{Number(dataObj[billType === 0 ? 'cnyRechargeTotal' : 'cnyDeductionTotal']).toFixed(2)}</span></> : null
                            }
                            {
                                currency !== 0 ? <><b>$</b>
                                <span>{Number(dataObj[billType === 0 ? 'usdRechargeTotal' : 'usdDeductionTotal']).toFixed(2)}</span></> : null
                            }
                            {/* <span className="desc">
                                {billType === 1 ? '充值总额是该用户所有充值金额的总计' : '扣费总额是该用户所有扣费金额的总计'}
                            </span> */}
                        </span>
                    </div>
                    <div>
                        <RadioGroup arr={timeArr} sendParent={handleSwitchRangeType} value={rangeType} />
                        <Select
                            style={{ marginLeft: 20, width: 160 }}
                            value={currency}
                            onChange={handleCurrencyChange}>
                            <Option value={-1}>全部</Option>
                            <Option value={0}>CNY</Option>
                            <Option value={2}>USD</Option>
                        </Select>

                        { billType === 0 ? <Button type="primary" style={{ marginLeft: 20 }} onClick={handleAddPay}>新增充值</Button> : null }
                    </div>
                </div>
            </section>
            <main ref={tableContainer}>
            {
                    tableHeight > 0 ? 
                    <Table
                    locale={{ emptyText: '暂无数据' }}
                    loading={tableLoading}
                    dataSource={dataSource}
                    columns={billType === 0 ? rechargeColumns : deductionColumns}
                    tableLayout="fixed"
                    rowKey='id'
                    scroll={{ x: 'auto', y: document.body.clientHeight - tableHeight - 160 }}
                    pagination={pagination}
                    onChange={handleTableChange} />
                     : null
            }
            </main>
            <ModalComponent
                title={'新增充值'}
                showFooter={false}
                size={477}
                closable={true}
                visible={modalShow}
                modalContentClass="addPayModal"
                onClick={() => setModalShow(false)}
            >
                <Form
                    {...layout}
                    form={form}
                    ref={formRef}
                    name="register"
                    onFinish={handleFinish}
                    scrollToFirstError
                >
                    <Form.Item
                        name="currency"
                        label={'充值账户'}
                        rules={[
                            {
                                required: true,
                                message: '充值账户是必选的',
                            },
                        ]}
                    >
                        <Select placeholder={'USD/CNY'} >
                            <Option disabled={dataObj.usdOpened === 0} value={2}>USD</Option>
                            <Option disabled={dataObj.cnyOpened === 0} value={0}>CNY</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="num"
                        label={'充值金额'}
                        rules={[
                            {
                                required: true,
                                message: '充值金额是必填的',
                            },
                            {
                                pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                message: '最多输入两位小数',
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} placeholder={'请输入充值金额'} min={0} />
                    </Form.Item>

                    <Form.Item
                        name="remark"
                        label={'备注'}
                    >
                        <Input placeholder={'请输入备注'} maxLength={20} />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" onClick={handleCancel}>
                            取消
                        </Button>
                        <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </ModalComponent>
        </div>
    )
}

export default withRouter(ElectricOrder);